import MenuCampaign from "../../layout/MenuCampaign";
import UserTable from "../../table/UserTable";
import { useEffect, useState } from "react";
import DetailUser from "./DetailUser";

import api from "../../../api";
function User() {
  const [listRoleUser, setListRoleUser] = useState(null);
  const [RoleUser, setRoleUser] = useState(null);
  const [showtab, setShowtab] = useState("info");
  const [showDetail, setShowDetail] = useState(false);
  const [user, setUser] = useState(null);
  const [detailUser, setDetailUser] = useState(null);
  const [filterUser, setFilterUser] = useState({
    page: null,
    "filter[campaigns_count_between]": null,
    "filter[search]": null,
    "filter[province_name]": null,
    "filter[status]": null,
    "filter[fundings_sum_amount_between]":null
  });
  const GetUser = (filterUsers) => {
    setFilterUser(filterUsers)
    let fill = "";
    Object.entries(filterUser).map(([key, value]) =>
      filterUser[key]
        ? (fill = fill + "&" + key + "=" + value)
        : null
    );
    api
      .get(
        "/api/ethis-users?include=profile.province,funded_campaigns_count,fundings_sum_amount" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setUser(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const ondownload = (tipe) => {
    let fill = "";
    Object.entries(filterUser).map(([key, value]) =>
      filterUser[key]
        ? (fill = fill + "&" + key + "=" + value)
        : null
    );
      api
        .get("/api/ethis-users/download?" + fill, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          responseType: "blob",
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "users.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("Axios",error);
        });
    
  };
  const onSearch=(tipe,keyword)=>{
      filterUser["page"]=null
      filterUser["filter[search]"]=keyword;
      setFilterUser(filterUser);
      GetUser(filterUser)
    
  }
  const getRoleUser=()=>{
    api
      .get(
        "/api/ethis-roles?include=users_count",
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setListRoleUser(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  }
  const GetAll=()=>{

  }
  useEffect(() => {
    GetUser(filterUser);
    getRoleUser()
  }, []);
  const onDetail = (id) => {
    setDetailUser(id)
    setShowDetail(!showDetail);
    //data detail label,
  };
  const onTab = (name) => {
    setShowtab(name);
    //data detail label,
  };
  const onChangeRole = (data,id) => {
    filterUser["filter[role_id]"]=id
    GetUser(filterUser)
    setRoleUser(data);
  }
  
  const onBack=()=>{
    setShowDetail(!showDetail)
  }
  const onPaginate = (page, tipe) => {

    filterUser["page"] = page;
    GetUser(filterUser)
  
  
};
  return (
    <div>
      {showDetail ? (
        <div>
          <DetailUser 
          tipe={RoleUser?RoleUser["name"]?RoleUser["name"]:null:null}
          data={detailUser}
          onTab={onTab}
          tab={showtab}
          onClickDetail={onDetail}
          onBack={onBack}
          />
        </div>
      ) : (
        <div>
          <MenuCampaign
            label="User"
            onClick={onChangeRole}
            tipe="user"
            user={RoleUser}
            listRole={listRoleUser}
            filter={filterUser} search={onSearch}
            getData={GetUser}
            download={ondownload}
          />
         <UserTable tipe={RoleUser} onClickDetail={onDetail} data={user} filterPaginate={onPaginate}/>
        </div>
      )}
    </div>
  );
}

export default User;
