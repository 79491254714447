import MenuCampaign from "../../layout/MenuCampaign";
import CampaignTable from "../../table/CampaignTable";
import React, { useEffect, useState } from "react";
import DetailCampaign from "./DetailCampaign";
import api from "../../../api";
function Campaign() {
  let bahasa=localStorage.getItem("bahasa")
  const [listTable, setListTable] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const onClick = () => setListTable(!listTable);
  const [campaign, setCampaign] = useState(null);
  const [detailCampaign, setDetailCampaign] = useState(null);
  const [filterCampaign, setFilterCampaign] = useState({
    page: null,
    "filter[target_between]": null,
    "filter[end]": null,
    "filter[search]": null,
    "filter[title]": null,
    "filter[in_province]": null,
    "filter[status]": null,
    "filter[in_period]": null,
  });
  const GetCampaign = (filterCampaigns,id) => {
    setFilterCampaign(filterCampaigns)
    let fill = "";
    Object.entries(filterCampaign).map(([key, value]) =>
      filterCampaign[key]
        ? (fill = fill + "&" + key + "=" + value)
        : null
    );
    api
      .get(
        "/api/campaigns?include=province,batch,akadType,owner,descriptions,galleries" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setCampaign(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onSearch=(tipe,keyword)=>{
      filterCampaign["page"]=null
      filterCampaign["filter[title]"]=keyword;
      setFilterCampaign(filterCampaign);
      GetCampaign(filterCampaign)
    
  }
  useEffect(() => {
    GetCampaign(filterCampaign);
  }, []);
  const onDetail = (detail) => {
    setShowDetail(!showDetail);
    setDetailCampaign(detail)
   
  };
  const onBack = (id) => {
    setShowDetail(!showDetail);
  };
  const ondownload = (tipe) => {
    let fill = "";
    Object.entries(filterCampaign).map(([key, value]) =>
      filterCampaign[key]
        ? (fill = fill + "&" + key + "=" + value)
        : null
    );
      api
        .get("/api/campaigns/download?" + fill, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          responseType: "blob",
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "campaign.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("Axios",error);
        });
    
  };
  const onShort = (value,tipe) => {

      filterCampaign["sort"] = value;
    setFilterCampaign(filterCampaign);
    GetCampaign(filterCampaign);
    
  };
  const onPaginate = (page, tipe) => {

      filterCampaign["page"] = page;
      GetCampaign(filterCampaign)
    
    
  };
  
  return (
    <div>
      {showDetail ? <DetailCampaign onClickBack={onBack} detailCampaign={detailCampaign} /> : (
        <div>
          <MenuCampaign download={ondownload} label={bahasa==="en"?"Campaign":"Kampanye"} tipe="campaign" filter={filterCampaign} search={onSearch}
            getData={GetCampaign}/>
          <CampaignTable onClickDetail={onDetail} data={campaign} detail={null} filterPaginate={onPaginate} onFilter={onShort} />
        </div>
      )}
    </div>
  );
}

export default Campaign;
