import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
function Dropdown(props) {
  const { label,custom,aditional } = props;
  let cus=custom;
  if(custom===null){
    cus="right-0 w-48";
  }
  return (
    <Menu as="div" className="relative mr-3 self-center z-20">
      <div>
        <Menu.Button className={aditional+" flex items-center border border-light-slate rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-25 py-[6px] px-2"}>
          <span className=" ml-3 small text-light-slate">{label}</span>
          <span className="text-light-slate link-16">
            <Icon path={mdiChevronDown} size={1} />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cus+" absolute p-4 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
        >
          {props.children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
