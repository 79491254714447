import Badges from "../../form/Badges";
import Buttons from "../../form/Buttons";
import SortFilterMenu from "../../layout/SortFilterMenu";

import {
  mdiFileDocumentMultiple,
  mdiMagnify,
  mdiChevronLeft,
  mdiChevronRight,
  mdiArrowLeft,
} from "@mdi/js";
import Icon from "@mdi/react";
import HeaderUser from "../../layout/HeaderUser";
import { useEffect, useState } from "react";
import api from "../../../api";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import Pagination from "../../layout/Pagination";

function DetailUser(props) {
  const { detail, clickList, tipe, onTab, tab, data, onBack } = props;
  let bahasa = localStorage.getItem("bahasa");
  let isi;
  const [campaign, setCampaign] = useState(null);
  const [user, setUser] = useState(null);
  const [info, setInfo] = useState(null);
  const [address, setAddress] = useState(null);
  const [filterCampaign, setFilterCampaign] = useState({
    page: null,
    "filter[target_between]": null,
    "filter[end]": null,
    "filter[search]": null,
    "filter[title]": null,
    "filter[in_province]": null,
    "filter[status]": null,
    "filter[ended_at]": null,
  });

  const GetCampaign = (filterCampaigns) => {
    setFilterCampaign(filterCampaigns);
    let fill = "";
    Object.entries(filterCampaign).map(([key, value]) =>
      filterCampaign[key] ? (fill = fill + "&" + key + "=" + value) : null
    );
    api
      .get(
        "/api/campaigns?filter[owner_id]" +
          data +
          "&include=province,batch,akadType,owner,descriptions,galleries" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setCampaign(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onSearch = (tipe, keyword) => {
    filterCampaign["filter[title]"] = keyword;
    setFilterCampaign(filterCampaign);
    GetCampaign(filterCampaign);
  };
  const onFilter = (name, value) => {
    filterCampaign[name] = value;
    setFilterCampaign(filterCampaign);
  };
  const GetAll = () => {
    let endp = [
      "/api/ethis-users/" +
        data +
        "?include=roles,profile,profile.job,profile.jobSector,profile.education,profile.religion,profile.subDistrict.district.city.province,documents.type",
      "/api/campaigns?filter[owner_id]" +
        data +
        "&include=province,batch,akadType,owner,descriptions,galleries",
    ];
    Promise.allSettled(
      endp.map((endpoint) =>
        api.get(endpoint, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      )
    )
      .then(function (response) {
       
        let user = response[0].value.data.data;
        setUser(user);
        setInfo({
          Religion: user.profile["religion"]["name"],
          "Marital Status": user.profile["marriage_status"],
          "Place of Birth": user.profile.birth_place,
          "Date of Birth": user.profile.birth_date,
          "Last Education": user.profile["education"]["name"],
          "Monthly Income": null,
          Job: user.profile["job"]["name"],
          "Job Sector": user.profile["job_sector"]["name"],
          "Source of Funding": null,
        });
        setAddress({
          Citizenship: null,
          Province:
            user.profile["sub_district"]["district"]["city"]["province"][
              "name"
            ],
          District: user.profile["sub_district"]["district"]["name"],
          "Sub District": user.profile["sub_district"]["name"],
        });
        setCampaign(response[1].value.data);
      })
      .catch(function (error) {
        console.log("Axios Error :: ", error);
      });
  };
  const onApplly = () => {
    GetCampaign(filterCampaign);
  };
  const onPaginate = (page, tipe) => {
    filterCampaign["page"] = page;
    GetCampaign(filterCampaign);
  };
  useEffect(() => {
    GetAll();
  }, []);
  switch (tab) {
    case "info":
      isi = info;
      break;
    case "address":
      isi = address;
      break;
  }
  return (
    <div>
      {user ? (
        <div>
          <div className="flex justify-between">
            <div className="flex">
              <div className="flex h3 leading-7 text-purple-100 self-center">
                <button className="mr-2" onClick={onBack}>
                  <Icon path={mdiArrowLeft} size={1} />
                </button>
                <div className="capitalize">Detail {tipe} Funder</div>
              </div>
            </div>

            <div className="flex justify-end">
              <Buttons
                icon="true"
                size="small"
                tipe="primary"
                label={"Download Detail User"}
              >
                <Icon
                  path={mdiFileDocumentMultiple}
                  size={1}
                  className="mr-2"
                />
              </Buttons>
            </div>
          </div>

          <div className="grid grid-cols-11 gap-4 my-[26px] w-full">
            <div className=" col-span-4">
              <div className="bg-white rounded-lg p-6">
              <img
                className="w-20 h-20 flex-shrink-0 mx-auto rounded-full"
                src={user.avatar}
                alt="profile"
              />
              <h3 className="mt-4 text-center text-slate body !font-semibold text-base leading-[140%]">
                {user.first_name + " " + user.last_name}
              </h3>
              <h3 className="text-light-slate text-center small !font-medium leading-[140%]">
                {user.email}
              </h3>
              <h3 className="text-light-slate text-center small !font-medium leading-[140%]">
                {user.phone}
              </h3>

              <HeaderUser detail={tab} onTab={onTab} tipe={tipe} />

              <div className="mt-6 text-left">
                {tab === "address" ? (
                  <div className="pretitle !text-[12px] text-light-slate leading-[14px] tracking-[0.03em] uppercase mb-4">
                    ID Card Address{" "}
                  </div>
                ) : null}
                <dl
                  className={
                    tab === "company" || tab === "document"
                      ? "grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-1"
                      : "grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2"
                  }
                >
                  {tab === "document"
                    ? user.documents.map((doc) => (
                        <div className="border border-grey-50 w-full py-2 px-4 rounded-lg flex">
                          <img
                            src={doc.document_image}
                            className="w-12 h-8 rounded-lg"
                          />
                          <div className="ml-4 flex-1 body text-base leading-[140%] tracking-wider self-center">
                            {doc.type["name"]}
                          </div>
                          <div className=" self-center">
                            <Badges tipe="unverified" label="SEE DOCUMENT" />
                          </div>
                        </div>
                      ))
                    : Object.keys(isi).map((data) => (
                        <div key={data} className="sm:col-span-1">
                          <dt className="small !font-medium text-light-slate leading-[140%]">
                            {data}
                          </dt>
                          <dd className=" body text-base !font-semibold text-slate leading-[140%] tracking-wider">
                            {isi[data]}
                          </dd>
                        </div>
                      ))}
                </dl>
                {tab === "address" ? (
                  <div>
                    <div className="mt-6 pretitle !text-[12px] text-light-slate leading-[14px] tracking-[0.03em] uppercase mb-4">
                      Current Address{" "}
                    </div>
                    <span className="small !font-medium leading-[140%] text-slate">
                     { "...., "+address["Sub District"]+", "+address["District"]+", "+address["Province"]}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            </div>
            <div className="col-span-7">
              <div className="bg-white rounded-lg p-4">
                <div className="flex justify-between">
                  <div className="self-center small !font-semibold leading-[140%] text-purple-100">
                    Funding Campaign
                    <span className="mx-2">
                      <Badges
                        label={campaign ? campaign.data.result.length : 0}
                        tipe="number"
                      />
                    </span>
                  </div>
                  <div className="flex">
                    <SortFilterMenu
                      tipe={tipe}
                      detail={detail}
                      clickList={clickList}
                      filter={onFilter}
                      filterData={filterCampaign}
                      onApplly={onApplly}
                    />
                  </div>
                  <div className="flex">
                    <div className="relative text-gray-400 focus-within:text-gray-600">
                      <input
                        id="search-field"
                        name="search-field"
                        className="block border border-light-slate rounded-lg py-2 pl-10 w-[212px] pr-3 text-light-slate placeholder-gray-500 sm:text-sm bg-white"
                        placeholder="Search Campaign"
                        type="search"
                      />
                      <div
                        className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                        aria-hidden="true"
                      >
                        <span className="text-light-slate link-16 px-2">
                          <Icon path={mdiMagnify} size={1} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {campaign
                  ? campaign.data.result
                    ? campaign.data.result.map((key) => (
                        <div className="grid grid-cols-5 mt-2 p-4 border border-grey-100 rounded-lg">
                          <div>
                            <img
                              src={key.galleries[0]["media_path"]}
                              className="w-[100px] h-[100px] rounded-md"
                            />
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.status === "on_going" ? (
                                <Badges tipe="ongoing" label="ON GOING" />
                              ) : (
                                <Badges tipe="finished" label="FINISHED" />
                              )}
                            </span>
                          </div>
                          <div>
                            <p className="text-sm text-light-slate">Title</p>
                            <p className="text-sm text-slate font-medium">
                              {key.title}
                            </p>

                            <p className="mt-2 text-sm text-light-slate">
                              Beneficiary
                            </p>
                            <p className="text-sm text-slate font-medium">
                              Belum Di Setting
                            </p>
                            {tipe === "beneficiary" ? null : (
                              <p className="mt-2 text-sm text-light-slate">
                                Batch
                              </p>
                            )}
                            <p className="text-sm text-slate font-medium">
                              {key.batch.name}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-light-slate">Location</p>
                            <p className="text-sm text-slate font-medium">
                              {key.province.name}
                            </p>

                            <p className="mt-2 text-sm text-light-slate">
                              Goal
                            </p>
                            <p className="text-sm text-slate font-medium">
                              <CurrencyFormat
                                value={key.target}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                              />
                            </p>
                            {tipe === "beneficiary" ? null : (
                              <div>
                                <p className="mt-2 text-sm text-light-slate">
                                  Funding Amount
                                </p>
                                <p className="text-sm text-slate font-medium">
                                  Belum disetting
                                </p>
                              </div>
                            )}
                          </div>
                          <div>
                            <p className="text-sm text-light-slate">Raised</p>
                            <p className="text-sm text-slate font-medium">
                              <CurrencyFormat
                                value={key.campaigns_sum_collected_fund}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                              />{" "}
                              ( {key.profit_ratio}%)
                            </p>

                            <p className="mt-2 text-sm text-light-slate">
                              Nisbah
                            </p>
                            <p className="text-sm text-slate font-medium">
                              {" "}
                              {key.profit_ratio}%
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-light-slate">Duration</p>
                            <p className="text-sm text-slate font-medium">
                              {key.timeline} Month
                            </p>

                            <p className="mt-2 text-sm text-light-slate">
                              End date
                            </p>
                            <p className="text-sm text-slate font-medium">
                              {key.ended_at}
                            </p>
                            <p className="text-[10px] text-slate font-medium ">
                              <Moment
                                duration={key.started_at}
                                date={key.ended_at}
                              />{" "}
                            </p>
                          </div>
                        </div>
                      ))
                    : null
                  : null}

                <Pagination data={campaign} filterPaginate={onPaginate} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DetailUser;
