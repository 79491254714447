import {
    mdiStarOutline
  } from "@mdi/js";
  import Icon from "@mdi/react";
function Grid(props) {
    const{label,nominal,tanggal,total}=props;
  return (
    <div className="col-span-1 p-4 bg-white rounded-lg">
      <div className="flex justify-between">
        <span className="text-[14px] text-slate leading-4 mt-[14px]">{label}</span>
        <span className="text-light-slate flex">
          <Icon path={mdiStarOutline} size={1} />
        </span>
      </div>
      <div className="text-[10px] text-light-slate leading-4 mt-[14px]">Total Fund</div>
      <div className="small !font-semibold leading-[140%]">{nominal} </div>
      <div className="text-[10px] text-light-slate leading-4 mt-1">Periode</div>
      <div className="text-[12px] !font-semibold leading-[140%]">{tanggal} </div>
      <div className="text-[10px] text-light-slate leading-4 mt-1">Total Campaign</div>
      <div className="text-[12px] !font-semibold leading-[140%]">{total} </div>
    </div>
  );
}

export default Grid;
