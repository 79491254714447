
import {
  mdiMagnify,
  mdiBell,
  mdiWeb,
  mdiChevronDown,
  mdiMenu,
  mdiLogoutVariant,
  mdiCog
} from "@mdi/js";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { NavLink } from "react-router-dom";
function NavBar(props) {
  const {onClick,onBahasa}=props;
 let bahasa=localStorage.getItem("bahasa")
 const changeBahasa=(b)=>{
   onBahasa(b)
 }
  const onLogout=()=>{
    sessionStorage.removeItem("token");
    onClick();
  }

  return (
    <div className="flex flex-col flex-1 lg:pl-64">
      <div className="sticky top-0 z-50 flex flex-shrink-0 h-16 bg-white border-b border-indigo-100 lg:border-none">
        <button
          type="button"
          className="px-4 text-gray-400 border-r border-indigo-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-25 lg:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <span className="text-light-slate link-16">
            <Icon path={mdiMenu} size={1} />
          </span>
        </button>
        <div className="flex justify-between flex-1 px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="flex flex-1">
            {/* <form className="flex w-full md:ml-0" action="#" method="GET">
              <label className="sr-only">Search</label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                  aria-hidden="true"
                >
                  <span className="text-light-slate link-16">
                    <Icon path={mdiMagnify} size={1} />
                  </span>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder={bahasa==="en"?"Search transactions":"Pencarian Transaksi"}
                  type="search"
                />
              </div>
            </form> */}
          </div>
          <div className="flex items-center ml-4 md:ml-6">
            <Menu as="div" className="relative mx-5">
              <div>
                <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-25 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                  <span className="text-light-slate link-16">
                    <Icon path={mdiWeb} size={1} />
                  </span>
                  <span className="hidden ml-3 text-sm font-medium tracking-wider text-light-slate link-16 lg:block">
                    {bahasa==="en"?"English":"Indonesia"}
                  </span>
                  <span className="text-light-slate link-16">
                    <Icon path={mdiChevronDown} size={1} />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0  w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  <button
                    className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                    role="menuitem"
                    id="user-menu-item-0"
                    onClick={()=>changeBahasa("en")}
                  >
                    English
                  </button>
                  <button
                    className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                    role="menuitem"
                    id="user-menu-item-1"
                    onClick={()=>changeBahasa("id")}
                  >
                    Indonesia
                  </button>
                </Menu.Items>
              </Transition>
            </Menu>
            <button
              type="button"
              className="p-1 mr-5 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-25"
            >
              <span className="sr-only">View notifications</span>
              <span className="text-light-slate link-16">
                <Icon path={mdiBell} size={1} />
              </span>
            </button>

            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-25 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                  <img className="w-8 h-8 rounded-full" src={JSON.parse(localStorage.getItem("user")).avatar} alt="" />
                  <span className="hidden ml-3 text-sm font-medium tracking-wider text-light-slate link-16 lg:block">
                    <span className="sr-only">Open user menu for </span>{JSON.parse(localStorage.getItem("user")).name}
                  </span>
                  <span className="text-light-slate link-16">
                    <Icon path={mdiChevronDown} size={1} />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  <NavLink to={"/setting"}>
                  <button
                    className="flex px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                    role="menuitem"
                    id="user-menu-item-1"
                  >
                     <Icon path={mdiCog} size={1} className="mr-[12.28px]"/>
                    {bahasa==="en"?"Settings":"Pengaturan"}
                  </button>
                  </NavLink>
                  <button
                    className="flex px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                    role="menuitem"
                    id="user-menu-item-2"
                    onClick={onLogout}
                  >
                    <Icon path={mdiLogoutVariant} size={1} className="mr-[12.28px]"/>
                    {bahasa==="en"?"Logout":"Keluar"}
                  </button>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <main>
        <div className="flex flex-col justify-between w-full h-full ">
          <div className="fixed w-full bg-white md:w-3">
            <span className="flex p-3 bg-isi rounded-t-2xl"></span>
          </div>
          <div className="fixed right-0 hidden w-3 bg-white md:block">
            <span className="flex p-3 bg-isi rounded-t-2xl"></span>
          </div>
          <div>
            <div>
              <div className="px-10 mt-6 text-md bock"></div>
            </div>
            <div className="px-4 mx-auto max-w sm:px-4 md:px-10 bg-isi">
              {props.children}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NavBar;
