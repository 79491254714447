import logo from "../../assets/logo.png";
function AuthImage(props) {
    const {image}=props
  return (
    <div className="hidden sm:flex sm:w-1/2 min-h-screen bg-side flex-col">
      <div className="flex mx-8 my-8">
        <img src={logo} alt="frday logo" className="w-[142px]" />
      </div>
      <div className="flex justify-center self-center h-full">
        <img src={image} alt="" className="h-[496.41px] px-20" />
      </div>
    </div>
  );
}

export default AuthImage;
