function List(props) {
  const { nama, nominal, tipe,user } = props;
  return (
    <div className="grid grid-cols-4 pt-6">
      <div className="col-span-3 flex self-center">
        <div className="col-span-1 h-8 w-8 rounded-full">{props.children}</div>
        <div className="col-span-3 block ml-3">
          <div className="  body font-medium text-base leading-[160%] tracking-wider self-center">
            {nama}
          </div>
          <div className="font-work font-medium text-[12px] leading-[140%] text-light-slate flex">
            {user}
          </div>
        </div>
      </div>
      <div className="col-span-1 block ">
        <div className="small font-medium text-[14px] leading-[140%] flex justify-end">
          {nominal}
        </div>
        <div className="font-work font-medium text-[12px] leading-[140%] text-light-slate flex justify-end">
          {tipe}
        </div>
      </div>
    </div>
  );
}

export default List;
