function Button(props) {
    const {tipe,size,label,icon,full,click,grid,modal,aktif,filter,change,type,name}=props;
        var data="";
        var disable=false;
        const onChange=()=>{
            if(grid!=null){
                grid();
            }
            if(click!=null){
                click();
            }
            if(modal!=null){
                modal();
            }
            if(filter!=null){
                change(filter);
            }
          }
    switch(tipe){
        case "primary":
            data=data+" bg-purple-100 text-white";
            break;
        case "secondary":
            data=" bg-purple-25 text-purple-100 ";
            break;
        case "tertiary":
            data=data+" bg-white text-purple-100 border border-purple-25";
            break;
        case "disable":
            data=data+" bg-dorian text-light-slate"
            disable=true;
            break;
        case "light":
            data=data+" bg-white text-light-slate border border-light-slate";
            break;
        case "dropdown":
            data=data+aktif+" bg-white text-light-slate ";
            break;
        default:
            break;
    }
    if(size==="small"){
        data=data+" text-[12px] leading-[14px] tracking-[0.03em]";
        if(icon!=null){
            data=data+" px-6 py-[9px]";
        }else{
            data=data+" px-4 py-[9px]";
        }
    }else if(size==="large"){
        data=data+" text-[12px] leading-[14px] tracking-[0.04em]";
        if(icon==="true"){
            data=data+" py-4 px-6";
        }else{
            data=data+" p-4";
        }
    }else{
        data=data+" text-[12px] leading-[14px] tracking-[0.04em]";
        if(icon==="true"){
            data=data+" py-[6px] px-[9px]";
        }else{
            data=data+" p-[9px]";
        }
    }
    if(full!=null){
        data=data+" w-full";
    }
    
    
            
  return (
    <button name={name} disabled={disable} className={data+" button-work flex justify-center rounded-lg"} onClick={onChange} type={type}>
      {props.children}
      <span className="self-center">{label}</span>
    </button>
  );
}

export default Button;
