import { Routes, Route,useNavigate, Navigate } from "react-router-dom";
import NavBar from "./components/layout/NavBar";
import SideBar from "./components/layout/SideBar";
import Batch from "./components/pages/Batch";
import Campaign from "./components/pages/Campaign";
import Dashboard from "./components/pages/Dashboard";
import Dire from "./components/pages/Dire";
import Settings from "./components/pages/Settings";
import User from "./components/pages/User";
import Login from "./components/pages/Login";
import Reset from "./components/pages/Reset";
import CheckEmail from "./components/pages/CheckEmail";
import SetPassword from "./components/pages/SetPassword";
import { useEffect, useState } from "react";
export default function App() {
  const navigate = useNavigate();

  const onLogin=()=>{
    navigate("/", { replace: true });
  }
  const [bahasa,setBahasa]=useState(localStorage.getItem("bahasa")!=null?localStorage.getItem("bahasa"):localStorage.setItem("bahasa","en"))
  const [profile,setprofile]=useState(null)
 const onBahasa=(b)=>{
  localStorage.setItem("bahasa",b)
   setBahasa(b)
   
   
 }
 const onChangeProfile=(data)=>{
  localStorage.setItem('user', JSON.stringify(data))
  setprofile(JSON.stringify(data))
 }
 useEffect(() => {
  
  console.log(localStorage.getItem("bahasa"))
}, [bahasa]);
  return (
    <div>
      {sessionStorage.getItem("token") ? (
        <div>
          <SideBar />
          <NavBar onClick={onLogin} onBahasa={onBahasa}>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/batch" element={<Batch />} />
              <Route exact path="/campaign" element={<Campaign />} />
              <Route exact path="/user" element={<User />} />
              <Route exact path="/dire" element={<Dire />} />
              <Route exact path="/setting" element={<Settings onChangeProfile={onChangeProfile}/>} />
            </Routes>
          </NavBar>
        </div>
      ) : (
        <Routes>
          <Route exact path="/login" element={<Login onClick={onLogin}/>} />
          <Route exact path="/" element={<Navigate to='/login' replace />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/setpassword" element={<SetPassword />} />
          <Route exact path="/check" element={<CheckEmail />} />
        </Routes>
      )}
    </div>
  );
}
