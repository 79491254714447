import Button from "../../form/Buttons";
import Badges from "../../form/Badges";
import {
  mdiMagnify,
  mdiUnfoldMoreHorizontal,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import Modal from "../../form/Modal";
import api from "../../../api";
import SortFilterMenu from "../../layout/SortFilterMenu";
function ModalUser(props) {
  const { isOpen, setModal, data } = props;
  let bahasa = localStorage.getItem("bahasa");
  const [funder, setFunder] = useState(null);
  const [filter, setFilter] = useState({
    "filter[search]": null,
    "filter[role_id]": 0,
  });
  let key=data;
  const getFunderModal = () => {
    let fill = "";
    Object.entries(filter).map(([key, value]) =>
      filter[key] ? (fill = fill + "&" + key + "=" + value) : null
    );
    api
      .get(
        "/api/ethis-users?filter[is_company]=" +
          key.id +
          "&include=profile.province,funded_campaigns_count,fundings_sum_amount,roles&limit=6" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setFunder(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onFilter = (name, value) => {
    filter[name] = value;
    setFilter(filter);
    getFunderModal();
  };
  const onSearch = (e) => {
    filter["filter[search]"] = e.target.value;
    setFilter(filter);
    getFunderModal();
  };
  useEffect(() => {
    getFunderModal()
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      setModal={setModal}
      aditional="max-w-[786px] h-[700px] "
    >
      <div className="grid grid-cols-8">
        <div className="self-center body !font-semibold leading-[140%] text-purple-100 col-span-1">
          Funder
          <span className="mx-2">
            <Badges
              label={funder ? funder.data.result.length : 0}
              tipe="number"
            />
          </span>
        </div>
        <div className="flex col-span-7 justify-end">
          <SortFilterMenu tipe="modal" filter={onFilter} filterData={filter} />
          <div className="flex">
            <div className="relative text-gray-400 focus-within:text-gray-600">
              <input
                id="search-field"
                name="search-field"
                className="block border border-light-slate rounded-lg py-2 pl-10 w-[212px] pr-3 text-light-slate placeholder-gray-500 sm:text-sm bg-white"
                placeholder={
                  bahasa == "en" ? "Search Campaign" : "Pencarian Kampanye"
                }
                type="search"
                onChange={(e) => onSearch(e)}
              />
              <div
                className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                aria-hidden="true"
              >
                <span className="text-light-slate link-16 px-2">
                  <Icon path={mdiMagnify} size={1} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-7 px-6 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table class="min-w-full divide-y divide-grey-75">
                <thead>
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en" ? "Name" : "Nama"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en" ? "Funder Type" : "Funder Tipe"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en" ? "Location" : "Lokasi"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en"
                            ? "Total Funding"
                            : "Total Pendapatan"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en"
                            ? "Funding date"
                            : "Tanggal Pendapatan"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-grey-75 bg-white">
                  {funder
                    ? funder.data.result
                      ? funder.data.result.map((user) => (
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <button >
                                <div className="flex">
                                  <img
                                    src={user.avatar}
                                    className="self-center h-8 w-8 mr-3"
                                  />
                                  <span className="text-[14px] text-slate leading-[140%] self-center">
                                    {user.first_name + " " + user.last_name}
                                  </span>
                                </div>
                              </button>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="text-[14px] text-slate leading-[140%]">
                                <Badges
                                  tipe={
                                    user.roles
                                      ? user.roles.name
                                      : "Tidak Ada Roles"
                                  }
                                  label={
                                    user.roles
                                      ? user.roles.name
                                      : "Tidak Ada Roles"
                                  }
                                />
                              </span>
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="text-[14px] text-slate leading-[140%]">
                                {user.profile.province.name}
                              </span>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                              <span className="text-[14px] text-slate leading-[140%]">
                                {user.fundings_sum_amount}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="text-[14px] text-slate leading-[140%]">
                                {null}
                              </span>
                            </td>
                          </tr>
                        ))
                      : null
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-7 flex items-center justify-between ">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50"
          >
            {" "}
            Previous{" "}
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50"
          >
            {" "}
            Next{" "}
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <nav
              className="relative inline-flex  -space-x-px"
              aria-label="Pagination"
            >
              <a
                href="#"
                className=" inline-flex items-center px-2 py-2 rounded-l-md   text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>

                <span className="text-light-slate">
                  <Icon path={mdiChevronLeft} size={1} />
                </span>
              </a>

              <a
                href="#"
                aria-current="page"
                className=" bg-purple-100 text-white rounded-md relative inline-flex items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                1{" "}
              </a>
              <a
                href="#"
                className="  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                2{" "}
              </a>
              <a
                href="#"
                className="  text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                3{" "}
              </a>
              <span className="relative inline-flex items-center px-4 py-2   text-sm font-medium text-gray-700">
                {" "}
                ...{" "}
              </span>
              <a
                href="#"
                className="  text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                8{" "}
              </a>
              <a
                href="#"
                className="  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                9{" "}
              </a>
              <a
                href="#"
                className="  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-medium"
              >
                {" "}
                10{" "}
              </a>
              <a
                href="#"
                className="relative inline-flex items-center px-2 py-2   text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>

                <span className="text-light-slate">
                  <Icon path={mdiChevronRight} size={1} />
                </span>
              </a>
            </nav>
          </div>
          <div>
            <Button
              label="Close"
              tipe="tertiary"
              size="small"
              modal={setModal}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalUser;
