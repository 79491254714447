import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
function ChartLine(props) {
    const{dataLine}=props;

  return (
    <div>
      {dataLine?<Line
        data={dataLine}
        options={{
          scales: {
            yAxes: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />:null}
    </div>
  );
}

export default ChartLine;
