import users from "../../assets/user.png";
import { useRef, useState } from "react";
import Input from "../form/Input";
import { mdiCellphoneMessage, mdiContentSave, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import Toggle from "../form/Toggle";
import Button from "../form/Buttons";
import api from "../../api";
function Settings(props) {
  const { onChangeProfile } = props;
  const hiddenFileInput = useRef();
  const [showImage, setShowImage] = useState(JSON.parse(localStorage.getItem("user")).avatar);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setShowImage(URL.createObjectURL(event.target.files[0]));
    props.handleFile(fileUploaded);
  };

  const [profile, setProfile] = useState({
    name: JSON.parse(localStorage.getItem("user")).name,
    email: JSON.parse(localStorage.getItem("user")).email,
  });
  const [email, setemail] = useState(null);
  const [name, setname] = useState(null);
  const onChange = (e) => {
    const name = e.target.getAttribute("name");
    profile[name] = e.target.value;
    if (name === "email") {
      setemail(true);
    } else {
      setname(true);
    }
    setProfile(profile);
  };
  const [password, setPassword] = useState(null);
  const [textpassword_old, settextPassword] = useState(null);
  const [message, setmessage] = useState(null);
  const [textpassword_new, settextnewPassword] = useState(null);
  const [passwordconfrim, setPasswordconfrim] = useState(null);
  const passwordValidation = (e) => {
    var text = e.target.value;
    if (text.length >= 8) {
      setPassword(true);
      settextPassword(text);
    } else {
      setPassword(false);
    }
  };
  const passwordConfrim = (e) => {
    var text = e.target.value;
    settextnewPassword(text);
    if (text.length >= 8) {
      setPasswordconfrim(true);
      settextnewPassword(text);
    } else {
      setPasswordconfrim(false);
    }
  };
  const SaveProfile = () => {
    api
      .put(
        "/api/settings/profile",
        { name: profile.name, email: profile.email },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        onChangeProfile(response.data.data);
        alert("Update Profile Success ")
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const SavePassword = () => {
    api
      .put(
        "/api/settings/password",
        { password_old: textpassword_old, password: textpassword_new },
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
          alert("Update Password Success")
      })
      .catch(function (error) {
        setmessage(error.response.data.message)
      console.log(error.response.data.message)
      });
  };
  return (
    <div>
      <div className="grid grid-cols-8">
        <div className="flex col-span-2 ">
          <div className="self-center leading-7 text-purple-100  h3">
            Setting
          </div>
        </div>
      </div>

      <div className="flex flex-col p-6 mt-6 bg-white rounded-lg">
        <div className="body text-slate font-bold">Profile</div>
        <div className="flex gap-8 mt-4 items-end">
          <div className="relative">
            <button
              onClick={handleClick}
              className="h-[26px] w-[26px] rounded-full bg-purple-100 right-0 absolute flex"
            >
              <span className="text-white self-center w-full justify-center flex ">
                <Icon path={mdiPencil} size={0.7} className="justify-center" />
              </span>
            </button>
            <input
              type="file"
              className="hidden"
              ref={hiddenFileInput}
              onChange={handleChange}
            />
            <img src={showImage} className="w-[112px] h-[112px] rounded-full" />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row">
              <span className="mr-[72px] self-center small !font-semibold leading-[140%] text-light-slate">
                Full name
              </span>
              <div className="w-[300px]">
                <Input
                  tipe="text"
                  name="name"
                  placeholder="Super Admin"
                  defa={JSON.parse(localStorage.getItem("user")).name}
                  change={onChange}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <span className="mr-[101px] self-center small !font-semibold leading-[140%] text-light-slate">
                Email
              </span>
              <div className="w-[300px]">
                <Input
                  tipe="email"
                  name="email"
                  placeholder="Your email"
                  defa={JSON.parse(localStorage.getItem("user")).email}
                  change={onChange}
                />
              </div>
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex-none">
            <Button
              tipe={email || name ? "primary" : "disable"}
              size="small"
              icon="true"
              label="SAVE"
              click={SaveProfile}
            >
              <Icon path={mdiContentSave} size={1} />
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white mt-6 p-6 rounded-lg">
        <div className="gap-6 flex flex-col self-auto items-center md:flex-row ">
          <div className="flex h2 text-slate">Change Password</div>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-[300px]">
              <Input
                name="password"
                label="password"
                tipe="password"
                id="password"
                change={passwordValidation}
                placeholder="Enter your password"
                aditional={
                  password === true
                    ? "!focus:ring-green-25 !border-green-100"
                    : password === null
                    ? null
                    : "!focus:ring-red-25 !border-red-100"
                }
              />
              <span
                className={
                  password === null || password === true
                    ? "hidden"
                    : "text-red-100 small leading-[140%]"
                }
              >
                Minimum password length is 8 characters
              </span>
            </div>
            <div className="w-[300px]">
              <Input
                change={passwordConfrim}
                name="password2"
                label="Confirm Password"
                tipe="password"
                id="password2"
                placeholder="Confirm your new password"
                aditional={
                  passwordconfrim === true
                    ? "!focus:ring-green-25 !border-green-100"
                    : passwordconfrim === null
                    ? null
                    : "!focus:ring-red-25 !border-red-100"
                }
              />
              <span
                className={
                  passwordconfrim === null || passwordconfrim === true
                    ? "hidden"
                    : "text-red-100 small leading-[140%]"
                }
              >
                Minimum password length is 8 characters
              </span>
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex">
            <Button
              tipe={passwordconfrim && password ? "primary" : "disable"}
              size="small"
              icon="true"
              label="SAVE"
              click={SavePassword}
            >
              <Icon path={mdiContentSave} size={1} />
            </Button>
          </div>
        </div>
        <span
          className={
            message === null ? "hidden" : "text-red-100 small leading-[140%]"
          }
        >
          {message}
        </span>
      </div>

      {/* <div className="bg-white mt-6 p-6 rounded-lg">
        <div>
          <div className="body !font-bold leading-[160%] tracking-wider text-slate">
            Two-factor authentication (2FA)
          </div>
          <div className="small !font-medium leading-[140%] text-light-slate">
            Keep your account secure by enabling 2FA via SMS or using a
            temporary one-time passcode (TOTP) from an authenticator app.
          </div>
        </div>
        <div className="flex justify-between gap-4 mt-4">
          <div className="p-[18px] border border-purple-25 rounded-lg">
            <div className="flex">
              <span className="text-light-slate mr-4 self-center">
                <Icon path={mdiCellphoneMessage} size={1} />
              </span>
              <div className="block">
                <div className="small !font-semibold leading-[140%] text-slate">
                  Text Message (SMS)
                </div>
                <div className="text-[12px] leading-[140%] text-light-slate">
                  Receive a one-time passcode via SMS each time you log in.
                </div>
              </div>
              <div className="ml-8 self-center">
                <Toggle />
              </div>
            </div>
          </div>
          <div className="p-[18px] border border-purple-25 rounded-lg">
            <div className="flex">
              <span className="text-light-slate mr-4 self-center">
                <Icon path={mdiCellphoneMessage} size={1} />
              </span>
              <div className="block">
                <div className="small !font-semibold leading-[140%] text-slate">
                  Authenticator App (TOTP)
                </div>
                <div className="text-[12px] leading-[140%] text-light-slate">
                  Use an app to receive a temporary one-time passcode each time
                  you log in.
                </div>
              </div>
              <div className="ml-8 self-center">
                <Toggle />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Settings;
