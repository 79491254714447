import Buttons from "../form/Buttons";
import HeaderMenu from "./HeaderMenu";
import { mdiFileDocumentMultiple, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import FilterMenu from "./FilterMenu";
import { useState } from "react";
function MenuCampaign(props) {
  const {
    detail,
    label,
    onClick,
    clickList,
    tipe,
    user,
    listRole,
    total,
    periode,
    campaign,
    filter,
    getData,
    search,
    download,
    id,
  } = props;
  let bahasa = localStorage.getItem("bahasa");
  let menu;
  if (tipe === "batch" && detail != null) {
    menu = (
      <HeaderMenu
        total={total}
        periode={periode}
        campaign={campaign}
        tipe={detail}
      />
    );
  } else if (tipe === "user") {
    menu = <HeaderMenu onClick={onClick} user={user} listRole={listRole} tipe="user" />;
  }
  const [keyword, setkeyword] = useState(null);
  const onSearch = (e) => {
    setkeyword(e.target.value);
  };
  const onSubmit = () => {
    search(tipe, keyword);
  };
  const onDownload = () => {
    download(tipe);
  };
  return (
    <div>
      <div className="grid grid-cols-8">
        <div className="flex col-span-2 ">
          <div className="self-center leading-7 text-purple-100  h3">
            {label}
          </div>
        </div>
        <div className="col-span-4 ">{menu}</div>
        <div className="flex justify-end col-span-2">
          <Buttons
            icon="true"
            size="small"
            tipe="primary"
            click={onDownload}
            label={bahasa === "en" ? "Download DATA " : "Unduh DATA " + tipe}
          >
            <Icon path={mdiFileDocumentMultiple} size={1} className="mr-2" />
          </Buttons>
        </div>
      </div>
      <div className="mt-8">
        <div className="grid grid-cols-8">
          <div className="col-span-3 flex">
            <div className="relative text-gray-400 focus-within:text-gray-600 mr-2">
              <div
                className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                aria-hidden="true"
              >
                <span className="text-light-slate link-16 px-2">
                  <Icon path={mdiMagnify} size={1} />
                </span>
              </div>
              <input
                id="search-field"
                name="search-field"
                className="block border border-light-slate rounded-lg py-2 pl-10 w-[260px] pr-3 text-light-slate placeholder-gray-500 sm:text-sm bg-isi"
                placeholder={bahasa === "en" ? "Search " : "Pencarian " + tipe}
                type="search"
                onChange={(e) => onSearch(e)}
              />
            </div>
            <Buttons
              label={bahasa === "en" ? "search" : "Cari"}
              tipe="primary"
              size="small"
              click={onSubmit}
            />
          </div>
          <FilterMenu
            tipe={tipe}
            detail={detail}
            clickList={clickList}
            filter={filter}
            getData={getData}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

export default MenuCampaign;
