function Input(props) {
    const {label,tipe,name,placeholder,value,change,aditional,defa}=props;
  return (
    <div>
      <label className="block text-sm font-medium text-slate capitalize">
        {label}
      </label>
      <div className="mt-1">
        <input
          type={tipe==="date"?"text":tipe}
          name={name}
          id={name}
          value={value}
          className={aditional+" shadow-sm focus:ring-purple-25 focus:ring-2 hover:focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md"}
          placeholder={placeholder}
          onChange={change}
          defaultValue={defa?defa:null}
          onFocus={tipe==="date"?(e) => (e.target.type = "date"):null}
        onBlur={tipe==="date"?(e) => (e.target.type = "text"):null}
        />
      </div>
    </div>
  );
}

export default Input;
