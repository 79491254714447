import "../../index.css";
import Button from "../form/Buttons";
import reset from "../../assets/Reset.png";
import Input from "../form/Input";
import { mdiKey, mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import AuthImage from "../layout/AuthImage";
import React from "react";
import { Link } from "react-router-dom";
function SetPassword() {
  const [password, setPassword] = React.useState(null);
  const [textpassword, settextPassword] = React.useState(null);
  const [passwordconfrim, setPasswordconfrim] = React.useState(null);
  const passwordValidation = (e) => {
    var text = e.target.value;
    if (text.length >= 8) {
      setPassword(true);
      settextPassword(text);
    } else {
      setPassword(false);
    }
  };
  const passwordConfrim = (e) => {
    var text = e.target.value;
    if (text === textpassword) {
      setPasswordconfrim(true);
    } else {
      setPasswordconfrim(false);
    }
  };
  return (
    <div className="flex">
      <AuthImage image={reset} />

      <div className="flex w-full sm:w-1/2 p-4 bg-white min-h-screen items-center justify-center">
        <div className="flex flex-col w-[365px] gap-8">
          <div className="w-[56px] justify-center  items-center p-4 rounded-full bg-side text-purple-100">
            <Icon path={mdiKey} size={1} />
          </div>
          <div>
            <h2 className="text-[40px] font-bold text-slate">
              Set new password
            </h2>
            <p className="text-light-slate">Please enter your details!</p>
          </div>
          <Input
            name="password"
            label="password"
            tipe="password"
            id="password"
            change={passwordValidation}
            placeholder="Enter your password"
            aditional={
              password === true
                ? "!focus:ring-green-25 !border-green-100"
                : password === null
                ? null
                : "!focus:ring-red-25 !border-red-100"
            }
          />
          <span
            className={
              password === null || password === true
                ? "hidden"
                : "text-red-100 small leading-[140%]"
            }
          >
            Minimum password length is 8 characters
          </span>
          <Input
            change={passwordConfrim}
            name="password2"
            label="Confirm Password"
            tipe="password"
            id="password2"
            placeholder="Confirm your new password"
            aditional={
              passwordconfrim === true
                ? "!focus:ring-green-25 !border-green-100"
                : passwordconfrim === null
                ? null
                : "!focus:ring-red-25 !border-red-100"
            }
          />
          <span
            className={
              passwordconfrim === null || passwordconfrim === true
                ? "hidden"
                : "text-red-100 small leading-[140%]"
            }
          >
            Your password doesn't match
          </span>
          <Button
            tipe={passwordconfrim && password ? "primary" : "disable"}
            size="large"
            label="Update Password"
            full="true"
          ></Button>
          <Link to={"/"}>
            <div className="flex body text-light-slate">
              <button className="mr-2">
                <Icon path={mdiArrowLeft} size={1} />
              </button>
              <span>Back to Login</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
