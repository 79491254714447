function Badges(props) {
    const {label,tipe}=props;
    var tambahan="";
    switch(tipe){
        case "ongoing":
            tambahan=tambahan+" bg-green-25 text-green-100"
            break;
        case "finished":
            tambahan=tambahan+" bg-blue-25 text-blue-100"
            break;
        case "completed":
            tambahan=tambahan+" bg-green-25 text-green-100"
            break;
        case "incomplete":
            tambahan=tambahan+" bg-red-25 text-red-100"
            break;
        case "batch1":
            tambahan=tambahan+" bg-orange-25 text-orange-100"
            break;
        case "batch2":
            tambahan=tambahan+" bg-dark-blue-25 text-dark-blue-100"
            break;
        case "number":
            tambahan=tambahan+" bg-purple-100 text-white !py-[2px] !px-[6px]"
            break;
        case "unverified":
            tambahan=tambahan+"bg-purple-25 text-purple-100";
            break;
        case "individual":
            tambahan=tambahan+" text-green-100 !text-[14px]";
            break;
        case "institution":
                tambahan=tambahan+" text-yellow-100 !text-[14px]";
                break;
        default:
            break;
      }
    return ( 
        <span className={tambahan+" p-1 pretitle rounded-md"}>
            {label}
        </span>
    );
}

export default Badges;