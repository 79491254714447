function NavDetailCampaign(props) {
    const {label,tipe,aktif,click}=props;
    return ( 
        <div className={tipe===aktif?"hover:show-scroll-bar whitespace-nowrap pr-5 small !font-semibold leading-[140%] py-2 border-b-2 border-purple-100 text-purple-100":"hover:show-scroll-bar whitespace-nowrap pr-5 small !font-semibold leading-[140%] py-2 border-b-2 border-grey-50 text-light-slate"}>
        <a href={"#"+tipe} onClick={()=>click(tipe)}>
        {label}
        </a>
        </div>
     );
}

export default NavDetailCampaign;