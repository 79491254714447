import { useState } from "react";
import { mdiFilterVariant, mdiViewModule, mdiViewList } from "@mdi/js";
import Icon from "@mdi/react";
import Dropdown from "../form/Dropdown";
import RadioButton from "../form/Radio";
import Badges from "../form/Badges";
import {
  RangeDatePicker,
  SingleDatePicker,
} from "react-google-flight-datepicker";
import "../../assets/styles/date.css";
import Buttons from "../form/Buttons";

function FilterMenu(props) {
  let bahasa=localStorage.getItem("bahasa")
  const { tipe, detail, clickList, filter, getData,id } = props;
  const range = {
    fund: {
      status: false,
      min: 0,
      max: 0,
    },
    campaign: {
      status: false,
      min: 0,
      max: 0,
    },
    goal: {
      status: false,
      min: 0,
      max: 0,
    },
    date: {
      start: null,
      end: null,
    },
  };
  const [changeIcon, setchangeIcon] = useState(false);
  const [filterData, setFilterData] = useState(filter);
  const [rangeData, setRangeData] = useState(range);
  const onFilter = (event) => {
    event.preventDefault();
    if (rangeData["fund"]["status"] === true) {
      filterData["filter[campaigns_sum_collected_fund_between]"] =
        rangeData["fund"]["min"] + "," + rangeData["fund"]["max"];
    }
    if (rangeData["campaign"]["status"] === true) {
      filterData["filter[campaigns_count_between]"] =
        rangeData["campaign"]["min"] + "," + rangeData["campaign"]["max"];
    }
    if (rangeData["goal"]["status"] === true) {
      filterData["filter[target_between]"] =
        rangeData["goal"]["min"] + "," + rangeData["goal"]["max"];
    }
    if (
      rangeData["date"]["start"] != null &&
      rangeData["date"]["end"] != null
    ) {
      filterData["filter[in_period]"] =
        rangeData["date"]["start"] + "," + rangeData["date"]["end"];
    }

    console.log(filterData);
    setFilterData(filterData);
    getData(filterData,id);
  };
  const convertDate = (dates) => {
    if (dates != null) {
      let year = dates.getFullYear();
      let month = dates.getMonth() + 1;
      let dt = dates.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return year + "-" + month + "-" + dt;
    }
  };
  const onChangeData = (event) => {
    const range = rangeData;
    const name = event.target.getAttribute("name");
    filterData[name] = event.target.value;
    setFilterData(filterData);
    switch (name) {
      case "filter[campaigns_sum_collected_fund_between]":
        range["fund"]["status"] = false;
        break;
      case "filter[campaigns_count_between]":
        range["campaign"]["status"] = false;
        break;
      case "filter[target_between]":
        range["goal"]["status"] = false;
        break;          
      default:
        break;
    }
    setRangeData(range);
    console.log(rangeData["campaign"]["status"]);
    console.log(event.target.value);
  };
  const onRange = (event) => {
    const range = rangeData;
    const temp = filterData;
    Object.entries(filterData).map((key) => (temp[key] = null));
    setFilterData(temp);
    switch (event.target.getAttribute("name")) {
      case "filter[campaigns_sum_collected_fund_between]":
        range["fund"]["status"] = true;
        break;
      case "filter[campaigns_count_between]":
        range["campaign"]["status"] = true;
        break;
      case "filter[in_period]":
        range["goal"]["status"] = true;
        break;
      default:
        break;
    }

    setRangeData(range);
  };
  const onDateChange = (start, end) => {
    const range = rangeData;
    range["date"]["start"] = convertDate(start);
    range["date"]["end"] = convertDate(end);
    setRangeData(range);
  };

  const onInput = (event) => {
    const range = rangeData;
    const data = event.target.getAttribute("id");
    switch (event.target.getAttribute("name")) {
      case "filter[campaigns_sum_collected_fund_between]":
        range["fund"][data] = event.target.value;
        break;
      case "filter[campaigns_count_between]":
        range["campaign"][data] = event.target.value;
        break;
      case "filter[in_period]":
        range["goal"][data] = event.target.value;
        break;
      default:
        break;
    }

    setRangeData(range);
    console.log(range);
  };
  const onReset = (event) => {
    const range = rangeData;
    const temp = filterData;
    Object.entries(filterData).map(([key, value]) => (temp[key] = null));
    setFilterData(temp);
    switch (event.target.getAttribute("name")) {
      case "filter[campaigns_sum_collected_fund_between]":
        range["fund"]["status"] = false;
        range["fund"]["min"] = 0;
        range["fund"]["max"] = 0;
        break;
      case "filter[campaigns_count_between]":
        range["campaign"]["status"] = false;
        range["campaign"]["min"] = 0;
        range["campaign"]["max"] = 0;
        break;
      case "filter[target_between]":
        range["goal"]["status"] = false;
        range["goal"]["min"] = 0;
        range["goal"]["max"] = 0;
        break;
      case "filter[in_period]":
        range["date"]["start"] = null;
        range["date"]["end"] = null;
        break;
      default:
        break;
    }

    setRangeData(range);
    onFilter(event);
    console.log(temp);
  };
  const click = () => setchangeIcon(!changeIcon);
  return (
    <div className="col-span-5 flex justify-end">
      <span className="text-light-slate link-16 pr-4 self-center">
        <Icon path={mdiFilterVariant} size={1} />
      </span>
      {tipe === "batch" && detail == null ? (
        <div className="flex">
          <form onSubmit={onFilter}>
            <Dropdown label={bahasa==="end"?"Total Fund":"Total Pendapatan"} custom="w-[300px] left-0">
              <RadioButton
                label="0-10.000.000"
                id="fund1"
                name="filter[campaigns_sum_collected_fund_between]"
                check={filterData}
                click={onChangeData}
                value="0,10000000"
              />
              <RadioButton
                label="10.000.000-50.000.000"
                id="fund2"
                check={filterData}
                name="filter[campaigns_sum_collected_fund_between]"
                click={onChangeData}
                value="10000000,50000000"
              />
              <RadioButton
                label="50.000.000-100.000.000"
                id="fund3"
                check={filterData}
                name="filter[campaigns_sum_collected_fund_between]"
                click={onChangeData}
                value="50000000,100000000"
              />
              <RadioButton
                label="100.000.000-1.000.000.000"
                id="fund4"
                check={filterData}
                name="filter[campaigns_sum_collected_fund_between]"
                click={onChangeData}
                value="100000000,1000000000"
              />
              <RadioButton
                name="filter[campaigns_sum_collected_fund_between]"
                range={true}
                check={rangeData["fund"]["status"]}
                click={onRange}
              >
                <input
                  type="number"
                  name="filter[campaigns_sum_collected_fund_between]"
                  id="min"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
                  placeholder="Min Range"
                  defaultValue={rangeData["fund"]["min"]}
                  onChange={(e) => onInput(e)}
                />
                <input
                  type="number"
                  name="filter[campaigns_sum_collected_fund_between]"
                  id="max"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
                  placeholder="Max Range"
                  defaultValue={rangeData["fund"]["max"]}
                  onChange={(e) => onInput(e)}
                />
              </RadioButton>
              <div className="flex justify-between pt-6">
                <button
                  name="filter[campaigns_sum_collected_fund_between]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  name="filter[campaigns_sum_collected_fund_between]"
                  type="submit"
                />
              </div>
            </Dropdown>
          </form>
          <form onSubmit={onFilter}>
            <Dropdown
              label={bahasa==="end"?"Periode":"Periode"}
              custom="right-0 w-[400px]"
              aditional="date-picker my-own-class-name"
            >
              <RangeDatePicker
                startDate={new Date()}
                endDate={new Date()}
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                disabled={false}
                className="my-own-class-name"
                startWeekDay="monday"
                onChange={(startDate, endDate) =>
                  onDateChange(startDate, endDate)
                }
              />
              <div className="flex justify-between pt-6">
                <button
                  name="filter[in_period]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  name="filter[in_period]"
                  type="submit"
                />
              </div>
            </Dropdown>
          </form>
        </div>
      ) : null}
      {(tipe === "batch" && detail == null) || tipe === "user" ? (
        <form onSubmit={onFilter}>
          <Dropdown label={bahasa==="end"?"Total Campaign":"Total Kampanye"} custom="w-[200px]">
            <RadioButton
              label="0-10"
              id="campaign1"
              name="filter[campaigns_count_between]"
              check={filterData}
              click={onChangeData}
              value="0,10"
            />
            <RadioButton
              label="10-50"
              id="campaign2"
              name="filter[campaigns_count_between]"
              check={filterData}
              click={onChangeData}
              value="10,50"
            />
            <RadioButton
              label="50-100"
              id="campaign3"
              name="filter[campaigns_count_between]"
              check={filterData}
              click={onChangeData}
              value="50,100"
            />
            <RadioButton
              label=">100"
              id="campaign4"
              name="filter[campaigns_count_between]"
              check={filterData}
              click={onChangeData}
              value="100,99999999999"
            />
            <RadioButton
              range={true}
              check={rangeData["campaign"]["status"]}
              click={onRange}
            >
              <input
                type="number"
                name="filter[campaigns_count_between]"
                id="min"
                className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
                placeholder="Min Range"
                onChange={(e) => onInput(e)}
              />
              <input
                type="number"
                name="filter[campaigns_count_between]"
                id="max"
                className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
                placeholder="Max Range"
                onChange={(e) => onInput(e)}
              />
            </RadioButton>
            <div className="flex justify-between pt-6">
              <button
                name="filter[campaigns_count_between]"
                class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                type="button"
                onClick={(e) => onReset(e)}
                role="none"
              >
                <span class="self-center" role="none">
                  reset
                </span>
              </button>
              <Buttons
                label={bahasa==="end"?"Apply":"Terapkan"}
                tipe="primary"
                size="small"
                name="filter[campaigns_count_between]"
                type="submit"
              />
            </div>
          </Dropdown>
        </form>
      ) : null}
      {tipe === "campaign" ||
      tipe === "user" ||
      (tipe === "batch" && detail != null) ? (
        <form onSubmit={onFilter}>
          <Dropdown label={bahasa==="end"?"Location":"Lokasi"} custom="w-[300px] left-0">
            <input
              type="text"
              name={tipe==="user"?"filter[province_name]":"filter[in_province]"}
              onChange={(e)=>onChangeData(e)}
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
              placeholder={bahasa === "en" ?"Input location name":"Masukan Nama Lokasi"}
            />

            <div className="flex justify-between pt-6">
              <button
                name={tipe==="user"?"filter[province_name]":"filter[in_province]"}
                class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                type="button"
                onClick={(e) => onReset(e)}
                role="none"
              >
                <span class="self-center" role="none">
                  reset
                </span>
              </button>
              <Buttons
                label={bahasa==="end"?"Apply":"Terapkan"}
                tipe="primary"
                size="small"
                type="submit"
                name={tipe==="user"?"filter[province_name]":"filter[in_province]"}
              />
            </div>
          </Dropdown>
        </form>
      ) : null}
      {tipe === "batch" && detail === null ? (
        <div className="ml-5">
          <Buttons
            label={changeIcon ? "List View" : "Grid View"}
            tipe="light"
            size="custom"
            icon="true"
            click={clickList}
            grid={click}
          >
            <span className="text-light-slate link-16 pr-2">
              {changeIcon ? (
                <Icon path={mdiViewList} size={1} />
              ) : (
                <Icon path={mdiViewModule} size={1} />
              )}
            </span>
          </Buttons>
        </div>
      ) : null}
      {tipe === "campaign" || detail === "batch" ? (
        <div className="flex">
          <form onSubmit={onFilter}>
            <Dropdown
              label={bahasa==="end"?"Goal":"Target"}
              custom="w-[300px]"
              aditional="date-picker my-own-class-name"
            >
              <RadioButton
                label="<10.000.000"
                id="goal1"
                name="filter[target_between]"
                check={filterData}
                click={onChangeData}
                value="0,10.000.000"
              />
              <RadioButton
                label="10.000.000-50.000.000"
                id="goal2"
                name="filter[target_between]"
                check={filterData}
                click={onChangeData}
                value="10000000,50000000"
              />
              <RadioButton
                label="50.000.000-100.000.000"
                id="goal3"
                name="filter[target_between]"
                check={filterData}
                click={onChangeData}
                value="50000000,100000000"
              />
              <RadioButton
                label="100.000.0000-1.000.000.000"
                id="goal4"
                name="filter[target_between]"
                check={filterData}
                click={onChangeData}
                value="100000000,1000000000"
              />
              <RadioButton
                range={true}
                check={rangeData["goal"]["status"]}
                click={onRange}
              >
                <input
                  type="number"
                  name="filter[target_between]"
                  id="min"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
                  placeholder="Min Range"
                  onChange={(e) => onInput(e)}
                />
                <input
                  type="number"
                  name="filter[target_between]"
                  id="max"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
                  placeholder="Max Range"
                  onChange={(e) => onInput(e)}
                />
              </RadioButton>
              <div className="flex justify-between pt-6">
                <button
                  name="filter[target_between]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  name="filter[target_between]"
                  type="submit"
                />
              </div>
            </Dropdown>
          </form>
          <form onSubmit={onFilter}>
            <Dropdown label="End date" custom="right-0 w-[400px]">
              <SingleDatePicker 
              endDate={new Date(2020, 0, 15)} 
              startDate={new Date()}
              onChange={(startDate, endDate) =>
                onDateChange(startDate, endDate)
              }
              />
            </Dropdown>
          </form>
          <form onSubmit={onFilter}>
            <Dropdown label="Status" custom="w-[200px] right-0">
              <RadioButton
                id="ongoing"
                name="filter[status]"
                check={filterData}
                click={onChangeData}
                value="on_going"
              >
                <Badges label="ON GOING" tipe="ongoing" />
              </RadioButton>
              <RadioButton
                id="finished"
                name="filter[status]"
                check={filterData}
                click={onChangeData}
                value="finished"
              >
                <Badges label="FINISHED" tipe="finished" />
              </RadioButton>

              <div className="flex justify-between pt-6">
                <button
                  name="filter[status]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  type="submit"
                  name="filter[status]"
                />
              </div>
            </Dropdown>
          </form>
        </div>
      ) : null}
      {tipe === "campaign" ? (
        <form onSubmit={onFilter}>
          <Dropdown label={bahasa==="en"?"Batch":"Kelompok"} custom="w-[200px] right-0">
            <input
              type="text"
              name="filter[title]"
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
              placeholder={bahasa==="en"?"Input batch name":"Masukan Nama Kelompok"}
              onChange={(e)=>onChangeData(e)}
              defaultValue={filterData["filter[title]"]}
            />

            <div className="flex justify-between pt-6">
              <button
                name="filter[title]"
                class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                type="button"
                onClick={(e) => onReset(e)}
                role="none"
              >
                <span class="self-center" role="none">
                  reset
                </span>
              </button>
              <Buttons
                label={bahasa==="end"?"Apply":"Terapkan"}
                tipe="primary"
                size="small"
                type="submit"
                name="filter[title]"
              />
            </div>
          </Dropdown>
        </form>
      ) : null}
      {tipe === "user" ? (
        <div className="flex">
          <form onSubmit={onFilter}>
            <Dropdown label={bahasa==="end"?"Total Funding":"Total Pendapatan"} custom="w-[300px] left-0">
              <RadioButton
                label="0-10.000.000"
                id="fund1"
                name="filter[fundings_sum_amount_between]"
                check={filterData}
                click={onChangeData}
                value="0,10000000"
              />
              <RadioButton
                label="10.000.000-50.000.000"
                id="fund2"
                check={filterData}
                name="filter[fundings_sum_amount_between]"
                click={onChangeData}
                value="10.000.000,50.000.000"
              />
              <RadioButton
                label="50.000.000-100.000.000"
                id="fund3"
                check={filterData}
                name="filter[fundings_sum_amount_between]"
                click={onChangeData}
                value="50.000.000,100.000.000"
              />
              <RadioButton
                label="100.000.000-1.000.000.000"
                id="fund4"
                check={filterData}
                name="filter[fundings_sum_amount_between]"
                click={onChangeData}
                value="100.000.000,1.000.000.000"
              />
              <RadioButton
                name="filter[fundings_sum_amount_between]"
                range={true}
                check={rangeData["fund"]["status"]}
                click={onRange}
              >
                <input
                  type="number"
                  name="filter[fundings_sum_amount_between]"
                  id="min"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
                  placeholder="Min Range"
                  defaultValue={rangeData["fund"]["min"]}
                  onChange={(e) => onInput(e)}
                />
                <input
                  type="number"
                  name="filter[fundings_sum_amount_between]"
                  id="max"
                  className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
                  placeholder="Max Range"
                  defaultValue={rangeData["fund"]["max"]}
                  onChange={(e) => onInput(e)}
                />
              </RadioButton>
              <div className="flex justify-between pt-6">
                <button
                  name="filter[fundings_sum_amount_between]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  name="filter[fundings_sum_amount_between]"
                  type="submit"
                />
              </div>
            </Dropdown>
          </form>
          <form onSubmit={onFilter}>
            <Dropdown label={bahasa==="end"?"Registration":"Registrasi"} custom="w-[200px] right-0">
              <RadioButton
                id="completed"
                name="filter[kyc_status]"
                check={filterData}
                click={onChangeData}
                value="10"
              >
                <Badges label={bahasa==="end"?"verified":"diferifikasi"} tipe="completed" />
              </RadioButton>
              <RadioButton
                id="completed"
                name="filter[kyc_status]"
                check={filterData}
                click={onChangeData}
                value="0"
              >
                <Badges label={bahasa==="end"?"unverified":"tidak diverifikasi"} tipe="incomplete" />
              </RadioButton>

              <div className="flex justify-between pt-6">
                <button
                  name="filter[kyc_status]"
                  class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                  type="button"
                  onClick={(e) => onReset(e)}
                  role="none"
                >
                  <span class="self-center" role="none">
                    reset
                  </span>
                </button>
                <Buttons
                  label={bahasa==="end"?"Apply":"Terapkan"}
                  tipe="primary"
                  size="small"
                  name="filter[kyc_status]"
                  type="submit"
                />
              </div>
            </Dropdown>
          </form>
        </div>
      ) : null}
    </div>
  );
}

export default FilterMenu;
