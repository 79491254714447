import { NavLink } from "react-router-dom";

function Menu(props) {
const {href,label} =props;
  return (
    <div className="my-3">
      <NavLink
        to={href}
        className={(navData) => (navData.isActive ?"pl-7 rounded-lg text-purple-100 tracking-wider justify-center hover:border-b-[3px] group flex items-center px-2 py-3 text-sm group bg-side font-semibold link-16":"pl-7 text-light-slate link-16 tracking-wider hover:text-purple-100 justify-center hover:border hover:border-purple-25 hover:rounded-lg group flex items-center px-2 py-3 text-sm font-medium group")}
        
      >
        {props.children}
        <div className="ml-3 grid grid-cols-2 w-full">
          {label}
        </div>
      </NavLink>
    </div>
  );
}

export default Menu;
