import { useEffect, useState } from "react";
import Dropdown from "../form/Dropdown";
import Badges from "../form/Badges";
import Buttons from "../form/Buttons";
import Button from "../form/Buttons";
import { SingleDatePicker } from "react-google-flight-datepicker";
import "../../assets/styles/date.css";
import RadioButton from "../form/Radio";
import api from "../../api";
function SortFilterMenu(props) {
  const [roleUser, setRoleUser] = useState(null);
  let bahasa = localStorage.getItem("bahasa");
  const { tipe, filter, filterData, onApplly } = props;
  const range = {
    goal: {
      min: 0,
      max: 0,
    },
    date: {
      start: null,
      end: null,
    },
  };
  let drop = {
    relevance: false,
    late: false,
    low: false,
    high: false,
  };
  const [rangeData, setRangeData] = useState(range);
  const [filteraktif, setFilterfilteraktif] = useState(drop);
  
  const change = (data) => {
    let temp = drop;
    temp[data] = !drop[data];
    setFilterfilteraktif(temp);
    console.log(drop);
  };
  const onSubmit = () => {
    if(range.goal.min==0 && range.goal.max==0){
      filter("filter[target_between]", null);
    }else{
      filter("filter[target_between]", range.goal.min + "," + range.goal.max);
    }
   
    filter("filter[ended_at]", range.date.end);
    onApplly();
  };
  const getRoles = () => {
    api
      .get("/api/ethis-roles?include=users_count", {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setRoleUser(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onChangeData = (event) => {
    const name = event.target.getAttribute("name");
    const value = event.target.value;
    filter(name, value);
  };
  const convertDate = (dates) => {
    if (dates != null) {
      let year = dates.getFullYear();
      let month = dates.getMonth() + 1;
      let dt = dates.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return year + "-" + month + "-" + dt;
    }
  };
  const onDateChange = ( end) => {
    const range = rangeData;
    range["date"]["end"] = convertDate(end);
    setRangeData(range);
  };
  const onGoalChange = (event) => {
    const range = rangeData;
    const data = event.target.getAttribute("id");
    range["goal"][data] = event.target.value;
    setRangeData(range);
    console.log(range);
  };
  const onReset = (e) => {
    filter(null, null);
  };
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div>
      {tipe === "modal" ? (
        <div className="Funder type">
          <Dropdown label="Sort by" custom="w-[200px] left-0">
            {roleUser
              ? roleUser.data.result.map((user) => (
                  <RadioButton
                    label={user.name}
                    id="individual"
                    name="filter[role_id]"
                    check={filterData}
                    value={user.id}
                    click={onChangeData}
                  />
                ))
              : null}
            {console.log(filterData)}
            <div className="flex justify-between pt-6">
              <button
                name="filter[role_id]"
                class=" bg-white text-purple-100 border border-purple-25 text-[12px] leading-[14px] tracking-[0.03em] px-4 py-[9px] button-work flex justify-center rounded-lg"
                type="button"
                onClick={(e) => onReset(e)}
                role="none"
              >
                reset
              </button>
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className="flex justify-center">
          <Dropdown label="Sort by" custom="w-[200px] left-0 !px-0">
            <Button
              label="Relevance"
              size="small"
              tipe="dropdown"
              aktif={
                filteraktif["relevance"]
                  ? "!bg-purple-100 !text-white !w-full !rounded-none"
                  : null
              }
              change={change}
              filter="relevance"
            />
            <Button
              label="Lates"
              size="small"
              tipe="dropdown"
              aktif={
                filteraktif["late"]
                  ? "!bg-purple-100 !text-white !w-full !rounded-none"
                  : null
              }
              change={change}
              filter="late"
            />
            <Button
              label="Funding: Low to Hight"
              size="small"
              tipe="dropdown"
              aktif={
                filteraktif["low"]
                  ? "!bg-purple-100 !text-white !w-full !rounded-none"
                  : null
              }
              change={change}
              filter="low"
            />
            <Button
              label="Funding: Hight to Low "
              size="small"
              tipe="dropdown"
              aktif={
                filteraktif["high"]
                  ? "!bg-purple-100 !text-white !w-full !rounded-none"
                  : null
              }
              change={change}
              filter="high"
            />
          </Dropdown>
          <Dropdown label="Filter by" custom="w-[250px] left-0">
            <div className="small !font-semibold text-slate leading-[140%]">
              <span>Location</span>
            </div>
            <input
              type="text"
              name="filter[in_province]"
              onChange={onChangeData}
              id="max"
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
              placeholder={
                bahasa === "en" ? "Input location name" : "Masukan Nama Lokasi"
              }
            />
            <div className="my-4 small !font-semibold text-slate leading-[140%]">
              <span>Goal</span>
            </div>
            <input
              type="number"
              name="filter[target_between]"
              onChange={onGoalChange}
              id="min"
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
              placeholder="Min Range"
            />
            <input
              type="number"
              name="filter[target_between]"
              onChange={onGoalChange}
              id="max"
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
              placeholder="Max Range"
            />
            <div className="my-4 small !font-semibold text-slate leading-[140%]">
              <span>End date</span>
            </div>

            <SingleDatePicker
              startDate={new Date(2020, 0, 15)}
              onChange={(startDate) =>
                onDateChange(startDate)
              }
            />

            <div className="my-4 small !font-semibold text-slate leading-[140%]">
              <span>Status</span>
            </div>
            <RadioButton
              id="ongoing"
              name="filter[status]"
              check={filterData}
              click={onChangeData}
              value="on_going"
            >
              <Badges label="ON GOING" tipe="ongoing" />
            </RadioButton>
            <RadioButton
              id="finished"
              name="filter[status]"
              check={filterData}
              click={onChangeData}
              value="finished"
            >
              <Badges label="FINISHED" tipe="finished" />
            </RadioButton>
            <div className="small !font-semibold text-slate leading-[140%]">
              <span>Batch</span>
            </div>
            <input
              type="text"
              name="filter[title]"
              id="max"
              className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md mt-4"
              placeholder={
                bahasa === "en" ? "Input batch name" : "Masukan Nama Kelompok"
              }
            />

            <div className="flex justify-between pt-6">
              <Buttons label="reset" tipe="tertiary" size="small" />
              <Buttons
                label="Apply"
                tipe="primary"
                size="small"
                click={onSubmit}
              />
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
}

export default SortFilterMenu;
