import {
  mdiUnfoldMoreHorizontal,
} from "@mdi/js";
import Icon from "@mdi/react";
import CurrencyFormat from "react-currency-format";
import Badges from "../form/Badges";
import Moment from "react-moment";
import Pagination from "../layout/Pagination";
import { useState } from "react";
function CampaignTable(props) {
  let bahasa = localStorage.getItem("bahasa");
  const { detailItem, onClickBack, onClickDetail, data,filterPaginate,onFilter } = props;
  const onBack = () => {
    onClickBack();
  };
  const [sort, setSort] = useState({
    title: "asc",
    batch_title: "asc",
    owner_first_name: "asc",
    province_name: "asc",
    target: "asc",
    collected_fund:"asc",
    profit_ratio: "asc",
    started_at: "asc",
    ended_at: "asc",
    status: "asc",
  });
  const onDetail = (detail) => {
    onClickDetail(detail);
  };
  const Filter = (name) => {
    console.log(name)
    if (sort[name] === "asc") {
      onFilter("-" + name);
      sort[name] = "dsc";
      setSort(sort,"campaign");
    } else {
      onFilter(name);
      sort[name] = "asc";
      setSort(sort,"campaign");
    }
  };
  return (
    <div>
      {data ? (
        <div>
          <div>
            <div className="mt-[30px] flex flex-col">
              <table className="min-w-full  border-separate table">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 text-left font-semibold "
                    >
                      <span className="text-light-slate flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 ">
                          No
                        </span>
                        <Icon path={mdiUnfoldMoreHorizontal} size={0.8} />
                      </span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="title" onClick={(e) => Filter("title")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? "Campaign" : "Kampanye"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    {detailItem ? null : (
                      <th scope="col" className="px-3 py-3.5 text-left">
                         <button name="title" onClick={(e) => Filter("title")}>
                        <div className="flex">
                          <span className="flex self-center text-[12px] text-light-slate leading-4">
                            {bahasa === "en" ? " Batch Name" : "Nama Kelompok"}
                          </span>
                          <span className="text-light-slate flex">
                            <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                          </span>
                        </div>
                        </button>
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="owner_first_name" onClick={(e) => Filter("owner_first_name")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en" ? "Beneficiary" : "Penerima"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                    <button name="province_name" onClick={(e) => Filter("province_name")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4">
                          {bahasa === "en" ? "Location" : "Lokasi"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 text-left font-semibold "
                    >
                       <button name="target" onClick={(e) => Filter("target")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? " Goal" : "Target"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="collected_fund" onClick={(e) => Filter("collected_fund")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? " Raised" : "Kenaikan"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                    <button name="profit_ratio" onClick={(e) => Filter("profit_ratio")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          Nisbah
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="ended_at" onClick={(e) => Filter("ended_at")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? "End date" : "Tanggal Berakhir"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                    <button name="status" onClick={(e) => Filter("status")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          Status
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data["data"]["result"]
                    ? data["data"]["result"].map((key) => (
                        <tr>
                          <td className="whitespace-normal py-4 pl-4 text-sm ">
                            <span className="small text-slate leading-[140%]">
                              1
                            </span>
                          </td>
                          <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() =>
                                onDetail({
                                  status: true,
                                  id: key.id,
                                  title: key.title,
                                  periode:
                                    key.started_at + " - " + key.ended_at,
                                  fund: key.campaigns_sum_collected_fund,
                                  campaign: key.campaigns_count,
                                })
                              }
                            >
                              <div className="flex">
                                <img
                                  src={key.galleries.media_path}
                                  alt=""
                                  className="self-center h-8 w-8 mr-3"
                                />
                                <span className="pr-3 text-[14px] text-slate leading-[140%] self-center">
                                  {key.title}
                                </span>
                              </div>
                            </button>
                          </td>
                          {detailItem ? null : (
                            <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                              <span className="small text-slate leading-[140%]">
                                {key.batch["title"]}
                              </span>
                            </td>
                          )}

                          <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.owner["first_name"] +
                                " " +
                                key.owner["last_name"]}
                            </span>
                          </td>
                          <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.province["name"]}
                            </span>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                            <span className="text-[14px] text-slate leading-[140%]">
                              <CurrencyFormat
                                value={key.target}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                              />
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="text-[14px] text-slate leading-[140%]">
                                <CurrencyFormat
                                  value={key.collected_fund}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                />
                              </span>
                          </td>
                          <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.profit_ratio}%
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="block">
                              <span className="text-[14px] text-slate leading-[140%]">
                                {key.ended_at}
                              </span>
                              <div className="whitespace-normal text-[10px] text-light-slate leading-[140%]">
                                {
                                  <Moment
                                    duration={key.started_at}
                                    date={key.ended_at}
                                  />
                                }{" "}
                                more
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.status === "on_going" ? (
                                <Badges tipe="ongoing" label="ON GOING" />
                              ) : (
                                <Badges tipe="finished" label="FINISHED" />
                              )}
                            </span>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination data={data} filterPaginate={filterPaginate} detailItem={detailItem} onBack={onBack}/>
        </div>
      ) : null}
    </div>
  );
}

export default CampaignTable;
