function HeaderUser(props) {
  const { onTab } = props;
  const onClick = (data) => {
    onTab(data);
  };
  const { detail, tipe } = props;
  return (
    <div>
      <div className="flex w-full mt-4 justify-between border-grey-50 relative">
        <button
          onClick={() => onClick("info")}
          className={
            detail === "info"
              ? "border-purple-100 text-purple-100  pr-0  text-center border-b-2 small !font-semibold leading-[140%]"
              : "border-grey-50 text-light-slate hover:text-purple-100 hover:border-light-slate pr-0  text-center border-b-2 small !font-semibold leading-[140%]"
          }
        >
          Basic Info
        </button>
        <button
          onClick={() => onClick("address")}
          className={
            detail === "address"
              ? "border-purple-100 text-purple-100  pr-0  text-center border-b-2 small !font-semibold leading-[140%]"
              : "border-grey-50 text-light-slate hover:text-purple-100 hover:border-light-slate pr-0  text-center border-b-2 small !font-semibold leading-[140%]"
          }
        >
          Address
        </button>
        <button
          onClick={() => onClick("document")}
          className={
            detail === "document"
              ? "border-purple-100 text-purple-100   text-center border-b-2 small !font-semibold leading-[140%]"
              : "border-grey-50 text-light-slate hover:text-purple-100 hover:border-light-slate  text-center border-b-2 small !font-semibold leading-[140%]"
          }
        >
          Document
        </button>
      </div>
    </div>
  );
}

export default HeaderUser;
