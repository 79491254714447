function RadioButton(props) {
    const {label,id,value,name,check,click,range}=props;
    let data;
    if(range){
      data=check;
    }else{
      data=check[name]===value?true:check[name]==value?true:false;
 
    }
    return ( 
        <div className="relative flex items-start pb-[14px]">
      <div className="flex items-center h-5">
        <input
          id={id}
          aria-describedby="comments-description"
          name={name}
          type="radio"
          value={value}
          className="focus:ring-purple-100 h-4 w-4 text-purple-100 border-gray-300 rounded"
          defaultChecked={data}
          onClick={(e)=>click(e)}
          onChange={()=>{}}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="small text-light-slate">
          {label}
          {props.children}
        </label>
        
      </div>
    </div>
     );
}

export default RadioButton;