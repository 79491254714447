import {
  mdiArrowDown,
  mdiArrowUp,
} from "@mdi/js";
import Icon from "@mdi/react";
function Stats(props) {
  let bahasa=localStorage.getItem("bahasa")
    const {heading,number,condition,percent,compare}=props;
    var panah;
    var tambahan;
    if(condition==="up"){
      tambahan="text-green-100 ";
      panah=mdiArrowUp;
    }
    else{
      tambahan="text-red-100 ";
      panah=mdiArrowDown;
    }
  return (
    <div className=" col-span-1 p-6 bg-white rounded-lg transform transition duration-500 hover:scale-105 hover:border-grey-50 hover:shadow-md">
      <dt className="body font-semibold text-base leading-[140%] tracking-wider text-slate">{heading}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex pt-6">
          <div className="h3 mr-2">
          {number}
          </div>
          <div className={tambahan+"flex body font-medium text-base leading-[160%] tracking-wider self-center"}>
            {percent} <Icon path={panah} size={1}/>
          </div>
          
        </div>
      </dd>
      <div className="small text-light-slate pt-2">
      {bahasa==="en"?"Compared to ("+compare+" last year)":"Dibandingkan dengan ("+compare+" Tahun Lalu)"}
      </div>
    </div>
  );
}

export default Stats;
