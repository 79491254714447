import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import api from "../../api";
import Modal from "../form/Modal";
import BatchGrid from "../grid/BatchGrid";
import MenuCampaign from "../layout/MenuCampaign";

import BatchTable from "../table/BatchTable";
import CampaignTable from "../table/CampaignTable";

function Batch() {
  let bahasa = localStorage.getItem("bahasa");
  const [listTable, setListTable] = useState(false);
  const [showDetail, setShowDetail] = useState({
    status: false,
    id: null,
    title: null,
    periode: null,
    fund: null,
    campaign: null,
  });
  const [batch, setBatch] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [filter, setFilterBatch] = useState({
    page: null,
    "filter[campaigns_count_between]": null,
    "filter[in_period]": null,
    "filter[search]": null,
    "filter[title]": null,
    "filter[campaigns_sum_collected_fund_between]": null,
    "sort":null
  });
  const [filterDetail, setFilterDetail] = useState({
    page: null,
    "filter[target_between]": null,
    "filter[end]": null,
    "filter[title]": null,
    "filter[search]": null,
    "filter[in_province]": null,
    "filter[status]": null,
    "filter[search]": null,
    "filter[in_period]": null,
    "sort":null
  });
  const [downloadBatch, setdownloadBatch] = useState(null);
  const [downloadCampaign, setdownloadCampaign] = useState(null);

  const onClick = () => setListTable(!listTable);

  const onDetail = (d) => {
    setShowDetail(d);
    GetCampaign(filterDetail, d["id"]);
  };
  const ondownload = (tipe) => {
    if (tipe === "batch") {
      let fill = "";
      Object.entries(filter).map(([key, value]) =>
        filter[key] ? (fill = fill + "&" + key + "=" + value) : null
      );
      api
        .get("/api/campaigns/download?" + fill, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          responseType: "blob",
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "batch.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("Axios",error);
        });
    } else {
      let fill = "";
      Object.entries(filterDetail).map(([key, value]) =>
        filterDetail[key] ? (fill = fill + "&" + key + "=" + value) : null
      );
      api
        .get("/api/campaigns/download/" + fill, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          responseType: "blob",
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "campaign.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("Axios",error);
        });
    }
  };
  const GetBatch = (filters) => {
    setFilterBatch(filters);
    let fill = "";
    Object.entries(filter).map(([key, value]) =>
      filter[key] ? (fill = fill + "&" + key + "=" + value) : null
    );
    api
      .get(
        "/api/batches?" +
          "&include=campaigns_count,campaigns_sum_collected_fund" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setBatch(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const GetCampaign = (filterDetails, id) => {
    setFilterDetail(filterDetails);
    let fill = "";
    Object.entries(filterDetail).map(([key, value]) =>
      filterDetail[key] ? (fill = fill + "&" + key + "=" + value) : null
    );
    api
      .get(
        "/api/campaigns?filter[batch_id]=" +
          id +
          "&include=province,batch,akadType,owner,descriptions,galleries" +
          fill,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setCampaign(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onDetailCampaign = () => {};
  const onSearch = (tipe, keyword) => {
    if (tipe != "user") {
      filter["filter[title]"] = keyword;
      filter["page"] = null;
      setFilterBatch(filter);
      GetBatch(filter);
    } else {
      filterDetail["filter[search]"] = keyword;
      filterDetail["page"] = null;
      setFilterDetail(filterDetail);
      GetCampaign(filterDetail);
    }
  };
  const onPaginate = (page, tipe) => {
    if (tipe === "detail") {
      filterDetail["page"] = page;
    } else {
      filter["page"] = page;
      GetBatch(filter);
    }
  };
  const onShort = (value,tipe) => {
    console.log(tipe)
    if(tipe==="batch"){
    filter["sort"] = value;
    setFilterBatch(filter);
    GetBatch(filter);
    }else{
      filterDetail["sort"] = value;
    setFilterDetail(filterDetail);
    GetCampaign(filterDetail);
    }
  };
  useEffect(() => {
    GetBatch(filter);
    if (showDetail["status"]) {
      GetCampaign({ ...filterDetail });
    }
  }, []);

  return (
    <div>
      <div>
        <MenuCampaign
          clickList={onClick}
          label={bahasa === "en" ? "Batch" : "Kelompok"}
          tipe="batch"
          detail={null}
          filter={filter}
          download={ondownload}
          getData={GetBatch}
          search={onSearch}
        />
        {listTable ? (
          <BatchGrid
            onClickDetail={onDetail}
            data={batch}
            filterPaginate={onPaginate}
            onFilter={onShort}
          />
        ) : (
          <BatchTable
            onClickDetail={onDetail}
            data={batch}
            filterPaginate={onPaginate}
            onFilter={onShort}
          />
        )}
      </div>
      {showDetail["status"] ? (
        <Modal
          isOpen={showDetail["status"]}
          setModal={onDetail}
          aditional="max-w-[1300px] h-[700px] "
        >
          <div>
            <MenuCampaign
              label={showDetail["title"]}
              detail="batch"
              tipe="campaign"
              search={onSearch}
              getData={GetCampaign}
              id={showDetail["id"]}
              download={ondownload}
              total={
                <CurrencyFormat
                  value={showDetail["fund"]}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp "}
                />
              }
              periode={showDetail["periode"]}
              campaign={showDetail["campaign"]}
              filter={filterDetail}
            />
            <CampaignTable
              detail="batch"
              onClickBack={onDetail}
              onClickDetail={onDetailCampaign}
              data={campaign}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default Batch;
