function HeaderMenu(props) {
  const { user, onClick, tipe, total, listRole, periode, campaign } = props;
  const onChange = (event, id) => {
    console.log(event);
    onClick(event, id);
  };

  return (
    <div>
      {tipe === "user" ? (
        <div className="flex overflow-x-scroll ">
          <div className="flex gap-x-6">
              <div className="block">
                <div className="small text-light-slate leading-[140%] ">
                  User type
                </div>
              </div>
              {listRole
                ? listRole.data.result.map((key) => (
                    <button onClick={() => onChange(key.name, key.id)}>
                      <div
                        className={
                          user === key.name
                            ? "small text-purple-100 leading-[140%] border-b border-purple-100"
                            : "small text-light-slate leading-[140%] border-b border-light-slate"
                        }
                      >
                        {key.name}
                      </div>
                    </button>
                  ))
                : null}
          </div>
        </div>
      ) : (
        <div className="flex gap-x-6">
          <div className="block">
            <div className="text-[12px] text-light-slate leading-[140%]">
              Total fund
            </div>
            <div className="small !font-bold text-purple-100">{total}</div>
          </div>
          <div className="block">
            <div className="text-[12px] text-light-slate leading-[140%]">
              Preiode
            </div>
            <div className="small !font-semibold text-purple-100">
              {periode}
            </div>
          </div>
          <div className="block">
            <div className="text-[12px] text-light-slate leading-[140%]">
              Campaign
            </div>
            <div className="small !font-semibold text-purple-100">
              {campaign}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderMenu;
