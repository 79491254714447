import CurrencyFormat from "react-currency-format";
import Grid from "../card/Grid";
function BatchGrid(props) {
  const { onClickDetail,data } = props;
  const onDetail = (detail) => {
    onClickDetail(detail);
  };
  return (
    <div>
      {data ? (
        <div className="mt-[30px] grid grid-cols-6 gap-4 pb-4">
          {data["data"]
            ? data["data"]?data["data"]["result"].map((key) => (
                <button onClick={()=>onDetail(
                  {
                    status:true,
                    id: key.id,
                    title: key.title,
                    periode:  key.started_at+" - "+key.ended_at,
                    fund: key.campaigns_sum_collected_fund,
                    campaign: key.campaigns_count,
                  }
                )} className="text-left">
                  <Grid
                    label= {key.title}
                    nominal={<CurrencyFormat value={key.campaigns_sum_collected_fund} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />}
                    tanggal={key.started_at+" - "+key.ended_at}
                    total={key.campaigns_count}
                  />
                </button>
              ))
            :null: null}
        </div>
      ) : null}
    </div>
  );
}

export default BatchGrid;
