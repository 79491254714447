import "../../index.css";
import Button from "../form/Buttons";
import image from "../../assets/Login.png";
import Input from "../form/Input";
import { mdiHumanGreeting } from "@mdi/js";
import Icon from "@mdi/react";
import AuthImage from "../layout/AuthImage";
import React from "react";
import validator from "validator";
import { Link } from "react-router-dom";
import api from "../../api";
function Login(props) {
  const { onClick } = props;
  const logins={
    email: '',
    password: ''
}
  const [email, setemail] = React.useState(null);
  const [error, seterror] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [login] = React.useState(logins);
 
  const emailValidation = (e) => {
    let text = e.target.value;
    if (validator.isEmail(text)) {
      setemail(true);
      login["email"]=text;

    } else {
      setemail(false);
    }
  };
  const passwordValidation = (e) => {
    var text = e.target.value;
    if (text.length >= 8) {
      setPassword(true);
      login["password"]=text;
    } else {
      setPassword(false);
    }
  };
  const onSubmit=(e)=>{
    e.preventDefault();
    api.defaults.withCredentials = true;
    api.get('sanctum/csrf-cookie').then(response => {
      api.post('api/login',{email:login["email"],password:login["password"]}
      )
          .then(function (response) {
              //---set Authorization header ---
              api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.access_token;
              //token store in session storage
              sessionStorage.setItem('token', response.data.data.access_token);
              localStorage.setItem('user', JSON.stringify(response.data.data.user));
              console.log("token", response.data.data.access_token);
              onClick();
          })
          .catch(function (error) {
              seterror(true)
          });
    })

    
  }
  return (
    <div className="flex">
      <AuthImage image={image} />
      <div className="flex w-full sm:w-1/2 p-4 bg-white min-h-screen items-center justify-center">
        <div className="flex flex-col w-[349px] gap-4">
          <div className="w-[56px] justify-center  items-center p-4 rounded-full bg-side text-purple-100">
            <Icon path={mdiHumanGreeting} size={1} />
          </div>
          <form className="form-horizontal" onSubmit={onSubmit}>
            <div>
              <h2 className="text-[40px] font-bold text-slate">Welcome</h2>
              <p className="text-light-slate">Please enter your details!</p>
            </div>
            <span
              className={
                error === null || error === false
                  ? "hidden"
                  : "text-red-100 small leading-[140%]"
              }
            >
            Email or Password you entered does not belong to a specific account. Check your Email or Password and try again.
            </span>
            <Input
              name="Email"
              label="email"
              tipe="email"
              id="email"
              placeholder="Enter your email"
              change={emailValidation}
              aditional={
                email === true
                  ? "!focus:ring-green-25 !border-green-100"
                  : email === null
                  ? null
                  : "!focus:ring-red-25 !border-red-100"
              }
            />
            <span
              className={
                email === null || email === true
                  ? "hidden"
                  : "text-red-100 small leading-[140%]"
              }
            >
              Your email doesn't seem right. Please Check again!
            </span>
            <Input
              name="password"
              label="password"
              tipe="password"
              id="password"
              change={passwordValidation}
              placeholder="Enter your password"
              aditional={
                password === true
                  ? "!focus:ring-green-25 !border-green-100"
                  : password === null
                  ? null
                  : "!focus:ring-red-25 !border-red-100"
              }
            />
            <span
              className={
                password === null || password === true
                  ? "hidden"
                  : "text-red-100 small leading-[140%]"
              }
            >
              Minimum password length is 8 characters
            </span>
            <Link to={"/reset"}>
              <p className="text-light-slate mt-4 mb-4">
                Forgot your password?{" "}
                <span href="#" className="body font-medium text-purple-100">
                  Reset Password
                </span>
              </p>
            </Link>
            <Button
              tipe={email && password ? "primary" : "disable"}
              label="login"
              size="large"
              full="true"
              type="submit"
            ></Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
