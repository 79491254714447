import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
function ChartDoughnut(props) {
const {dataDoughnut}=props;
  return (
    <div>
      {dataDoughnut?<Doughnut
        data={dataDoughnut}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />:null}
    </div>
  );
}

export default ChartDoughnut;
