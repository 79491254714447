import Menu from "../card/Menu";
import CampaignIcon from "@mui/icons-material/Campaign";
import Icon from "@mdi/react";
import Logo from "../../assets/logo.png";
import { useEffect,useState } from "react";
import {
  mdiPoll,
  mdiAccount,
  mdiClipboardTextClock,
  mdiSelectGroup,
  mdiCog,
  mdiMenuOpen
} from "@mdi/js";
function SideBar() {
  let bahasa=localStorage.getItem("bahasa")
  return (
    <div>
      <div
        className="fixed inset-0 flex z-40 md:hidden"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75"
          aria-hidden="true"
        ></div>
        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              type="button"
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close sidebar</span>
              <span className="text-light-slate link-16">
                    <Icon path={mdiMenuOpen} size={1} />
                  </span>
            </button>
          </div>

          <div className="flex-shrink-0 flex items-center px-4 ml-3">
            <img className="h-8" src={Logo} alt="Frday" />
          </div>
          <div className="mt-5 flex-1 h-0 overflow-y-auto ml-3">
            <nav className="px-2 space-y-1">
              <Menu label={bahasa==="en"?"Dashboard":"Beranda"} href="/">
                <Icon path={mdiPoll} size={1} />
              </Menu>
              <Menu label={bahasa==="en"?"Batch":"Kelompok"} href="/batch">
                <Icon path={mdiSelectGroup} size={1} />
              </Menu>
              <Menu label={bahasa==="en"?"Campaign":"Kampanye"} href="/campaign">
                <CampaignIcon />
              </Menu>
              <Menu label={bahasa==="en"?"User":"Pengguna"} href="/user">
                <Icon path={mdiAccount} size={1} />
              </Menu>
              <Menu label="Dire" href="/dire">
                <Icon path={mdiClipboardTextClock} size={1}/>
              </Menu>
              <Menu label={bahasa==="en"?"Setting":"Pengaturan"} href="/setting">
                <Icon path={mdiCog} size={1} />
              </Menu>
            </nav>
          </div>
        </div>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </div>

      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-grow pt-5 bg-white bg-opacity-100">
          <div className="flex items-center flex-shrink-0 px-4 ml-3 py-3 justify-center">
            <img className="h-8 w-auto " src={Logo} alt="Workflow" />
          </div>
          <div className=" mx-5 py-0.5 border-b-[1px] border-[#ffffff14]"></div>
          <div
            className=" flex-1 flex flex-col"
            x-data="{roomOpen:false,eventOpen:false,venueOpen:false,notOpen:false}"
          >
            <nav className="flex-1 px-6 pb-4 space-y-1">
            <Menu label={bahasa==="en"?"Dashboard":"Beranda"} href="/">
                <Icon path={mdiPoll} size={1} />
              </Menu>
              <Menu label={bahasa==="en"?"Batch":"Kelompok"} href="/batch">
                <Icon path={mdiSelectGroup} size={1} />
              </Menu>
              <Menu label={bahasa==="en"?"Campaign":"Kampanye"} href="/campaign">
                <CampaignIcon />
              </Menu>
              <Menu label={bahasa==="en"?"User":"Pengguna"} href="/user">
                <Icon path={mdiAccount} size={1} />
              </Menu>
              <Menu label="Dire" href="/dire">
                <Icon path={mdiClipboardTextClock} size={1}/>
              </Menu>
              <Menu label={bahasa==="en"?"Setting":"Pengaturan"} href="/setting">
                <Icon path={mdiCog} size={1} />
              </Menu>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
