import {
  mdiFileDocumentMultiple,
  mdiArrowLeft,
  mdiMapMarker,
  mdiHandshake,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import Button from "../../form/Buttons";
import profile1 from "../../../assets/profile1.png";
import Badges from "../../form/Badges";
import List from "../../card/List";
import ChartLine from "../../chart/line";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import NavDetailCampaign from "./NavDetailCampaign";
import { useState } from "react";
import React from "react";

import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import api from "../../../api";
import ModalUser from "./ModalUser";
function DetailCampaign(props) {
  let bahasa=localStorage.getItem("bahasa")
  const { onClickBack,detailCampaign } = props;
  const [key, setDetailCampaign] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const [aktif,setAktif] = React.useState(0);
  const [dataline, setDataLine] = useState(null);
  const [filter, setFilter] = useState(true);
  const [dataDay, setLineDay] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(100, 212, 138, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(0, 183, 255, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(52, 76, 183, 1)",
      },
    ],
  });
  const [dataWeek, setLineWeek] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(100, 212, 138, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(0, 183, 255, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(52, 76, 183, 1)",
      },
    ],
  });
  const onAktif=(i)=>{
      setAktif(i)
  }
  
  const [funder,setFunder]=useState(null)
  
  const setModal = () => {
    setIsOpen(!isOpen);
  };
  const onBack = () => {
    onClickBack();
  };

 
  const getAll=()=>{
    console.log(detailCampaign)
    let endp=[
      "/api/campaigns/" +detailCampaign.id+"?include=province,batch,akadType,owner,descriptions,galleries",
      "/api/ethis-users?filter[funded_campaign_id]="+detailCampaign.id+"&include=profile.province,funded_campaigns_count,fundings_sum_amount,roles&limit=5",
      "/api/campaigns/1/statistics?period=daily",
      "/api/campaigns/1/statistics?period=weekly"

    ]
    Promise.allSettled(
      endp.map((endpoint) =>
        api.get(endpoint, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      )
    ).then(function (response) {
      setDetailCampaign(response[0].value.data.data);
      setFunder(response[1].value.data)
      response[2].value.data.data.map((key, i) => {
        dataDay.labels[i] = key.label;
        dataDay.datasets[0].data[i] = key.funding;
        dataDay.datasets[1].data[i] = key.ongoing_campaigns;
        dataDay.datasets[2].data[i] = key.finished_campaigns;
      });
      response[3].value.data.data.map((key, i) => {
        dataWeek.labels[i] = key.label;
        dataWeek.datasets[0].data[i] = key.funding;
        dataWeek.datasets[1].data[i] = key.ongoing_campaigns;
        dataWeek.datasets[2].data[i] = key.finished_campaigns;
      });
      setLineWeek(dataWeek);
      setLineDay(dataDay);
      setDataLine(dataDay)
    }).catch(function (error) {
      console.log("Axios Error :: ", error);
    });
   

  }
  const changeStatistic=(filters)=>{
    setFilter(filters)
    setDataLine(filters?dataDay:dataWeek);
  }

  useEffect(() => {
    getAll()
  }, []);
  const language=sessionStorage.getItem("bahasa");
  return (
    <div>
      {key ? (
          <div>
            <div className="flex justify-between">
              <div className="flex">
                <div className="flex h3 leading-7 text-purple-100 self-center">
                  <button className="mr-2" onClick={onBack}>
                    <Icon path={mdiArrowLeft} size={1} />
                  </button>
                  <div className="capitalize">{bahasa==="en"?"Detail Campaign":"Detail Kampanye"}</div>
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  icon="true"
                  size="small"
                  tipe="primary"
                  label={bahasa==="en"?"Download Detail Campaign":"Unduh Detail Kampanye"}
                >
                  <Icon
                    path={mdiFileDocumentMultiple}
                    size={1}
                    className="mr-2"
                  />
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-10 gap-4 my-[26px] w-full">
              <div className=" col-span-4">
                <div className="bg-white rounded-lg p-6">
                  <div>
                    <img
                      src={key.galleries[0]["media_path"]}
                      alt=""
                      className="w-full rounded-lg h-[215px]"
                    />
                  </div>
                  <div className="grid grid-cols-6 gap-2 mt-[9px]">
                    {key.galleries.map((image) => (
                      <div className="col-span-1">
                        <img
                          src={image.media_path}
                          alt=""
                          className="w-[58px] h-[58px] rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-between mt-4">
                    <div>
                      <span className="text-xl font-bold leading-[160%]">
                        {key.title}
                      </span>
                    </div>
                    <div>
                      <Badges tipe="ongoing" label="ON GOING" />
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="flex">
                      <div className="text-light-slate mr-3">
                        <Icon path={mdiMapMarker} size={1} />
                      </div>
                      <div className="w-[124px] ">
                        <span className="small !font-medium leading-[140%] text-light-slate">
                          {key.province["name"]}
                        </span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-light-slate rotate-45 mr-5 mt-[-8px]">
                        <Icon path={mdiHandshake} size={1} />
                      </div>
                      <div className="small !font-medium leading-[140%] text-light-slate">
                        {key.akad_type["name"]}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 p-4 border border-grey-75 rounded-lg">
                    <div className="flex justify-between text-light-slate text-[12px] leading-[14px]">
                      <div>
                        <span>{bahasa==="en"?"Raised so far":"Kenaikan Sejauh Ini"}</span>
                      </div>
                      <div>
                      <span>{bahasa==="en"?"Goal":"Target"}</span>
                      </div>
                    </div>
                    <div className="flex justify-between text-slate text-[16px] font-bold leading-[160%] ">
                      <div>
                        <span>
                          <CurrencyFormat
                            value={key.collected_fund}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                          />
                        </span>
                      </div>
                      <div>
                        <span>
                          <CurrencyFormat
                            value={key.target}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="relative w-full bg-gray-200 rounded-full h-2.5 bg-grey-100 mt-2">
                      <div className="absolute bg-green-50 h-2.5 rounded-full w-[100%]"></div>
                      <div
                        className="absolute bg-green-100 h-2.5 rounded-full "
                        style={{
                          width: (key.collected_fund / key.target) * 100 + "%",
                        }}
                      ></div>
                    </div>
                    <div className="flex justify-between text-light-slate text-[12px] leading-[14px] mt-2">
                      <div>
                        <span>{bahasa==="en"?"Raised so far":"Kenaikan Sejauh Ini"}</span>
                      </div>
                      <div>
                        <span>{bahasa==="en"?"Goal":"Target"}</span>
                      </div>
                    </div>
                    <div className="flex justify-between text-slate text-[16px] font-bold leading-[160%] ">
                      <div>
                        <span>
                          {Math.round((key.collected_fund / key.target) * 100) +
                            "%"}
                        </span>
                      </div>
                      <div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 p-4 border border-grey-75 rounded-lg">
                    <div className="grid grid-cols-9  text-light-slate text-[12px] leading-[14px]">
                      <div className="col-span-6">
                        <span>{bahasa==="en"?"Batch":"Kelompok"}</span>
                      </div>
                      <div className="col-span-3">
                        <span>{bahasa==="en"?"Timeline":"Linimasa"}</span>
                      </div>
                    </div>
                    <div className="body grid grid-cols-9 text-slate text-[16px] !font-bold leading-[160%] ">
                      <div className="col-span-6">
                        <span>{key.batch["title"]}</span>
                      </div>
                      <div className="col-span-3">
                        <span>{key.timeline} {bahasa==="en"?"Week":"Bulan"}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-9  text-light-slate text-[12px] leading-[14px] mt-2">
                      <div className="col-span-6">
                        <span>Projected Nisbah</span>
                      </div>
                      <div className="col-span-3">
                        <span>{bahasa==="en"?"End Date":"Tanggal Berakhir"}</span>
                      </div>
                    </div>
                    <div className="body grid grid-cols-9 text-slate text-[16px] font-bold leading-[160%] ">
                      <div className="col-span-6">
                        <span>{key.profit_ratio}%</span>
                      </div>
                      <div className="col-span-3">
                        <div>
                          <span>{key.ended_at}</span>
                        </div>
                        <div className="text-[12px] font-medium leading-[14px]">
                          <span>
                            <Moment
                              duration={key.started_at}
                              date={key.ended_at}
                            />{" "}
                            more
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 p-4 border border-grey-75 rounded-lg">
                    <div className="flex justify-between text-light-slate text-[12px] leading-[14px]">
                      <div>
                        <span>Key Executive</span>
                      </div>
                    </div>
                    <div className="flex mt-3">
                      <div className="w-8 h-8 rounded-full">
                        <img src={profile1} />
                      </div>
                      <div className="ml-2 ">
                        <div className="body !font-semibold leading-[140%] tracking-wider text-base">
                          <span>
                            {key.owner["first_name"] +
                              " " +
                              key.owner["last_name"]}
                          </span>
                        </div>
                        <div className="text-light-slate text-[12px] leading-[14px]">
                          <span>Director of PT Jaya Impian Abadi</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 text-light-slate text-[12px] leading-[160%] text-justifys">
                      <span>
                        Mrs. Jilmi has more than 20 Days of experience in the
                        field of MICE events, brand activation production, and
                        field service outsourcing in marketing communication
                        projects.
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 p-4 border border-grey-75 rounded-lg">
                    <div className="text-purple-100 body !font-semibold text-base leading-[140%]">
                      <span>Funder</span>
                    </div>
                    {funder?funder.data.result?funder.data.result.map((user)=>(
                    <List
                      nama={user.first_name+" "+user.last_name}
                      nominal={<CurrencyFormat value={parseInt(user.fundings_sum_amount?user.fundings_sum_amount:0, 10)} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />}
                      tipe={null}
                      // user={user.roles?user.roles.name?user.roles.name:"Tidak Ada Roles":"Tidak Ada Roles"}
                    >
                      <img src={user.avatar} alt="" className="h-8 w-8"/>
                    </List>)):null:null
}
                    <div className="pt-6">
                      <Button
                        tipe="tertiary"
                        size="small"
                        label={bahasa==="en"?"Show more":"Tampilkan Lebih Banyak"}
                        full="true"
                        modal={setModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <div className="bg-white rounded-lg p-6 w-full">
                  <div className="flex overflow-x-scroll ">
                    <div className="flex">
                    {key.descriptions.map((des,i) => (
                      <NavDetailCampaign
                        label={bahasa==="en"?des.title:des.title_en}
                        tipe={i}
                        aktif={aktif}
                        click={onAktif}
                      />
                      ))}
                    </div>
                  </div>
                  <div
                    className="border border-grey-75 rounded-lg p-4 mt-6 "
                    id="statistic"
                  >
                    <div className="grid grid-cols-2 pb-6">
                      <div className="flex">
                        <div className="col-span-5 body font-semibold text-base leading-[140%] tracking-wider ">
                          Statistic
                        </div>
                        <span className=" ml-8 mr-2 font-medium text-light-slate">
                          Show
                        </span>
                        <Menu as="div" className="relative">
                          <div>
                            <Menu.Button className="flex max-w-xs ml-3">
                              <span className="self-center px-0 py-0 mx-0 my-0 text-sm font-semibold tracking-wider text-purple-100 border-0 link-16">
                              {filter?bahasa === "en" ? "Daily" : "Harian":bahasa === "en" ? "Weekly" : "Minguan"}
                              </span>
                              <span className="text-purple-100 link-16 ">
                                <Icon path={mdiChevronDown} size={1} />
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="user-menu-button"
                            >
                              <button
                                className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                                role="menuitem"
                                id="user-menu-item-0"
                                onClick={()=>changeStatistic(true)}
                              >
                                {bahasa === "en" ? "Daily" : "Harian"}
                              </button>
                              <button
                                className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                                role="menuitem"
                                id="user-menu-item-1"
                                onClick={()=>changeStatistic(false)}
                              >
                                {bahasa === "en" ? "Weekly" : "Minguan"}
                              </button>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>

                      <div className="flex justify-end gap-4 col-span-1">
                        <div className="flex ">
                          <div className="self-center w-2 h-2 rounded-full bg-green-75"></div>
                          <div className="self-center text-light-slate font-medium text-[10px] leading-[140%] ml-2">
                            Funder
                          </div>
                        </div>
                        <div className="flex">
                          <div className="self-center w-2 h-2 bg-dark-blue-100 rounded-full "></div>
                          <div className="self-center text-light-slate font-medium text-[10px] leading-[140%] ml-2">
                            Fundings
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChartLine dataLine={dataline} />
                    </div>
                  </div>
                  {key.descriptions.map((des,i) => (
                    <div className="py-6 border-b border-grey-50 " id={i}>
                      <div className="small !font-semibold leading-[140%] text-purple-100">
                        <span>{bahasa==="en"?des.title:des.title_en}</span>
                      </div>
                      <div className="my-2 body !font-semibold leading-[140%] text-purple-100 text-base tracking-wider">
                        <span>{bahasa==="en"?des.subtitle:des.subtitle_en}</span>
                      </div>
                      <div className="small !font-medium leading-[140%] text-light-slate">
                        <span>
                        {bahasa==="en"?des.description:des.description_en}
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <div className="py-6 border-b border-grey-50 " id="">
                        <div className="small !font-semibold leading-[140%] text-purple-100">
                          <span>Company Overview</span>
                        </div>
                        <div className="grid grid-cols-10 w-full mt-2">
                          <div className="col-span-1">
                            <img src={pt1} alt="" />
                          </div>
                          <div className="col-span-9">
                            <div className="body !font-semibold leading-[140%] tracking-wider text-slate">
                              <span>PT Jaya Impian Abadi</span>
                            </div>
                            <div className=" mt-2 small !font-medium leading-[140%] text-light-slate">
                              <span>
                                PT Jaya Impian Abadi ("Jaya Impian") received a
                                work order from PT Shell Indonesia ("Shell") for
                                the procurement of parcels, which is located in
                                Cakung, East Jakarta. For 5 Days, Shell has
                                trusted Jaya Impian Abadi as a partner,
                                especially in the Marketing Agency section. To
                                complete the work, Jaya Impian requires
                                financing for the procurement of goods and to
                                cover the operational costs of the work.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-10 w-full mt-2">
                          <div className="col-span-1">
                            <img src={pt2} alt="" />
                          </div>
                          <div className="col-span-9">
                            <div className="body !font-semibold leading-[140%] tracking-wider text-slate">
                              <span>Shell Indonesia</span>
                            </div>
                            <div className=" mt-2 small !font-medium leading-[140%] text-light-slate">
                              <span>
                                Shell in Indonesia carries out its business
                                activities in the upstream and downstream
                                sectors. In the downstream sector, Shell's
                                business activities include petroleum,
                                lubricants for industry, automotive and
                                transportation, fuels for the marine industry,
                                commercial fuels and bitumen. In the upstream
                                sector, Shell is a strategic partner of Inpex,
                                the operator of the Masela PSC which includes
                                the Abadi gas field.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                </div>
              </div>
            </div>
            <ModalUser isOpen={isOpen} setModal={setModal} data={detailCampaign}/>
          </div>
        ) : null}
    </div>
  );
}

export default DetailCampaign;
