import Button from "../form/Buttons";

import { mdiFileDocumentMultiple, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import Input from "../form/Input";
import Dropdown from "../form/Dropdown";
import DireTable from "../table/DireTable";
import "tw-elements";
import { useEffect, useState } from "react";
import api from "../../api";
import RadioButton from "../form/Radio";
function Dire() {
  const [filterDire, setFilterDire] = useState({
    page: null,
    "filter[in_period]": null,
    sort: null,
    limit: 8,
  });
  const [custom, setCustoms] = useState(false);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });
  const [dire, setDire] = useState(null);
  useEffect(() => {
    GetDire(filterDire);
  }, []);
  const onFilter = (value) => {
    filterDire["sort"] = value;
    setFilterDire(filterDire);
    GetDire();
  };
  const onChangeData = (event) => {
    const name = event.target.getAttribute("name");
    setCustoms(false);
    filterDire[name] = event.target.value;
    setFilterDire(filterDire);
    console.log(filterDire);
  };
  const onInput = (event) => {
    const name = event.target.getAttribute("name");
    if (name === "start" || name === "end") {
      date[name] = event.target.value;
      setDate(date);
      console.log(date);
    } else {
      filterDire[name] = event.target.value;
      setFilterDire(filterDire);
      console.log(filterDire);
    }
  };
  const ondownload = (tipe) => {
    let fill = "";
    Object.entries(filterDire).map(([key, value]) =>
      filterDire[key] ? (fill = fill + key + "=" + value + "&") : null
    );
      api
        .get("/api/dires/download?" + fill, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          responseType: "blob",
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "dires.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("Axios",error);
        });
    
  };
  const onCustom = () => {
    setCustoms(true);
    filterDire["limit"] = null;
    setFilterDire(filterDire);
    
  };
  const GetDire = () => {
    if(date.start!=null && date.end!=null){
      filterDire["filter[in_period]"]=date.start+","+date.end
      setFilterDire(filterDire)
    }
    let fill = "";
    Object.entries(filterDire).map(([key, value]) =>
      filterDire[key] ? (fill = fill + key + "=" + value + "&") : null
    );
    api
      .get("/api/dires?" + fill, {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setDire(response.data);
      })
      .catch(function (error) {
        console.log("login error response :: ", error);
      });
  };
  const onPaginate = (page, tipe) => {

    filterDire["page"] = page;
    GetDire(filterDire)
  
  
};
  return (
    <div>
      <div className="grid grid-cols-8">
        <div className="flex col-span-2 ">
          <div className="self-center leading-7 text-purple-100  h3">Dire</div>
        </div>
        <div className="col-span-4 "></div>
        <div className="flex justify-end col-span-2">
          <Button icon="true" size="small" tipe="primary" label="Download Dire" click={ondownload}>
            <Icon path={mdiFileDocumentMultiple} size={1} className="mr-2" />
          </Button>
        </div>
      </div>
      <div className="mt-[14px] rounded-lg bg-white p-4 flex gap-4">
        <div className="self-center">Filter</div>
        <div className="w-[300px] self-center">
          <Input
            tipe="date"
            name="start"
            placeholder="Start Date"
            change={onInput}
          />
        </div>
        <div className="w-[300px] self-center">
          <Input
            tipe="date"
            name="end"
            placeholder="End Date"
            change={onInput}
          />
        </div>
        <div className="mt-1">
          <Dropdown aditional="bg-white w-[214px] " label="Item per page">
            <RadioButton
              label="10"
              id="page"
              name="limit"
              check={filterDire}
              click={onChangeData}
              value="10"
            />
            <RadioButton
              label="25"
              id="page"
              name="limit"
              check={filterDire}
              click={onChangeData}
              value="25"
            />
            <RadioButton
              label="50"
              id="page"
              name="limit"
              check={filterDire}
              click={onChangeData}
              value="50"
            />
            <RadioButton
              label="100"
              id="page"
              name="limit"
              check={filterDire}
              click={onChangeData}
              value="100"
            />
            <RadioButton
              range={true}
              name="limit"
              check={custom}
              click={onCustom}
            >
              <input
                type="number"
                name="limit"
                onChange={(e) => onInput(e)}
                className="shadow-sm focus:ring-purple-25 focus:border-purple-25 block w-full sm:text-sm border-light-slate rounded-md "
                placeholder="Input Amount"
                click={onCustom}
              />
            </RadioButton>
          </Dropdown>
        </div>

        <div className="self-center">
          <Button
            tipe="primary"
            icon="true"
            size="small"
            label="SEARCH"
            click={GetDire}
          >
            <Icon path={mdiMagnify} size={1} className="mr-2" />
          </Button>
        </div>
      </div>
      <DireTable data={dire} onFilter={onFilter} filterPaginate={onPaginate}/>
    </div>
  );
}

export default Dire;
