import {
  mdiUnfoldMoreHorizontal,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import { NavLink } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Badges from "../form/Badges";
import Pagination from "../layout/Pagination";
import { useState } from "react";
function UserTable(props) {
  const { tipe, onClickDetail,onFilter,data,filterPaginate } = props;
  const onDetail = (id) => {
    onClickDetail(id);
  };
  const [sort, setSort] = useState({
    no: "asc",
    date: "asc",
    dire: "asc",
    debit: "asc",
    balance: "asc",
    note: "asc",
  });
  const Filter = (name) => {
    console.log(name)
    if (sort[name] === "asc") {
      onFilter("-" + name);
      sort[name] = "dsc";
      setSort(sort);
    } else {
      onFilter(name);
      sort[name] = "asc";
      setSort(sort);
    }
  };
  let bahasa=localStorage.getItem("bahasa")
  return (
    <div>
    {data ? (
    <div>
      <div>
        <div className="mt-[30px] flex flex-col">
          <table className="min-w-full  border-separate table">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 text-left font-semibold "
                >
                  <span className="text-light-slate flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      No
                    </span>
                    <Icon path={mdiUnfoldMoreHorizontal} size={0.8} />
                  </span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Name
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Email
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
                {tipe === "instituion" || tipe === "beneficiary" ? (
                  <th scope="col" className="px-3 py-3.5 text-left ">
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4">
                        Company Name
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </th>
                ) : null}
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Location
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
                {tipe === "instituion" || tipe === "individual" ? (
                  <th scope="col" className="px-3 py-3.5 text-left">
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4">
                        Total Funding
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </th>
                ) : null}
                <th
                  scope="col"
                  className="py-3.5 pl-4 text-left font-semibold "
                >
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Total Campaign
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Reg. Date
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4">
                      Status
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
            { data["data"]["result"]?data["data"]["result"].map((key,i)=>(
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                  <span className="small text-slate leading-[140%]">{i+1}</span>
                </td>
                <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                  <button onClick={() => onDetail(key.id)}>
                    <div className="flex">
                      <img
                        src={key.avatar}
                        alt=""
                        className="self-center h-8 w-8 mr-3"
                      />
                      <span className="pr-3 text-[14px] text-slate leading-[140%] self-center">
                        {key.first_name+" "+key.last_name}
                      </span>
                    </div>
                  </button>
                </td>

                <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                  <span className="small text-slate leading-[140%]">
                    {key.email}
                  </span>
                </td>
                {tipe === "instituion" || tipe === "beneficiary" ? (
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                    <span className="small text-slate leading-[140%]">
                      Company Name
                    </span>
                  </td>
                ) : null}

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span className="text-[14px] text-slate leading-[140%]">
                  {key.profile?key.profile["province"]["name"]:null}
                  </span>
                </td>
                {tipe === "instituion" || tipe === "individual" ? (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="text-[14px] text-slate leading-[140%]">
                    {/* Rp {key.fundings_sum_amount} */}
                    <CurrencyFormat value={parseInt(key.fundings_sum_amount, 10)} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                    </span>
                  </td>
                ) : null}
                <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                  <span className="text-[14px] text-slate leading-[140%]">
                    {key.funded_campaigns_count}
                  </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                 
                    <span className="text-[14px] text-slate leading-[140%]">
                      {key.created_at}
                    </span>
                
                </td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span className="text-[14px] text-slate leading-[140%]">
                    {key.kyc_status===10?<Badges tipe="completed" label="VERIFIED" />:<Badges tipe="incomplete" label="UNVERIFIED" />}
                    
                  </span>
                </td>
              </tr>
 )):null}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination data={data} filterPaginate={filterPaginate}  />
    </div>
     ) : null}
    </div>
  );
}

export default UserTable;
