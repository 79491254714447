import "../../index.css";
import Button from "../form/Buttons";
import reset from "../../assets/Reset.png";
import { mdiKeyAlert, mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import Input from "../form/Input";
import AuthImage from "../layout/AuthImage";
import React from "react";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
function Login(props) {
  const [email, setemail] = React.useState(null);
  const navigate = useNavigate();
  const emailValidation = (e) => {
    let text = e.target.value;
    if (validator.isEmail(text)) {
      setemail(true);
    } else {
      setemail(false);
    }
  };
  const onProses = () => {
    navigate("/check", { replace: true });
  };
  return (
    <div className="flex">
      <AuthImage image={reset} />

      <div className="flex w-full sm:w-1/2 p-4 bg-white min-h-screen items-center justify-center">
        <div className="flex flex-col w-[356px] gap-8">
          <div className="w-[56px] justify-center  items-center p-4 rounded-full bg-side text-purple-100">
            <Icon path={mdiKeyAlert} size={1} />
          </div>
          <div>
            <h2 className="text-[40px] font-bold text-slate">
              Forgot password?
            </h2>
            <p className="text-light-slate">
              Dont worry, we'll sent an email with instructions to reset your
              password.{" "}
            </p>
          </div>
          <div>
            <Input
              name="Email"
              label="email"
              tipe="email"
              id="email"
              placeholder="Enter your email"
              change={emailValidation}
              aditional={
                email === true
                  ? "!focus:ring-green-25 !border-green-100"
                  : email === null
                  ? null
                  : "!focus:ring-red-25 !border-red-100"
              }
            />
            <span
              className={
                email === null || email === true
                  ? "hidden"
                  : "text-red-100 small leading-[140%] "
              }
            >
              Your email doesn't seem right. Please Check again!
            </span>
          </div>

          <Button
            tipe={email ? "primary" : "disable"}
            label="Reset Your Password"
            size="large"
            full="true"
            click={onProses}
          ></Button>
          <Link to={"/"}>
            <div className="flex body text-light-slate">
              <button className="mr-2">
                <Icon path={mdiArrowLeft} size={1} />
              </button>
              <span>Back to Login</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
