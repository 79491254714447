import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "../form/Buttons";
function Pagination(props) {
  let bahasa = localStorage.getItem("bahasa");
  const { data, filterPaginate, detailItem, onBack } = props;
  const onPaginate = (e) => {
    let page;

    if (e === "prev") {
      var url = data.links.prev;
      var match = url ? url.match(/page=(\d+)/) : null;
      console.log(url + " match : " + match);
      if (match) {
        page = match[1];
      }
    } else if (e === "next") {
      var url = data.links.next;
      var match = url ? url.match(/page=(\d+)/) : null;
      if (match) {
        page = match[1];
      }
    } else if (e === "...") {
    } else {
      let value = e.target.value;
      page = value;
    }
    filterPaginate(page, null);
  };
  return (
    <div>
      {data ? (
        <div className="grid grid-cols-6">
          <div
            className={
              detailItem
                ? " px-4 py-3 flex items-center justify-between sm:px-6 col-span-4"
                : " px-4 py-3 flex items-center justify-between sm:px-6 col-span-6"
            }
          >
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-light-slate">
                  {bahasa === "en" ? "Showing" : "Menampilkan"}
                  <span className="font-bold px-1">
                    {data.meta.per_page > data.data.result.length
                      ? data.data.result.length
                      : data.meta.per_page}
                  </span>
                  {bahasa === "en" ? "to" : "sampai"}
                  <span className="font-bold px-1">{data.meta.to}</span>
                  {bahasa === "en" ? "of" : "dari"}
                  <span className="font-bold px-1">{data.meta.total}</span>
                  {bahasa === "en" ? "results" : "hasil"}
                </p>
              </div>
              <div>
                <nav
                  className="relative inline-flex  -space-x-px"
                  aria-label="Pagination"
                >
                  {data.meta.links.map((key) => {
                    if (key.label === "&laquo; Previous") {
                      return (
                        <button
                          onClick={(e) => onPaginate("prev")}
                          className=" inline-flex items-center px-2 py-2 rounded-l-md   text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous</span>

                          <span className="text-light-slate">
                            <Icon path={mdiChevronLeft} size={1} />
                          </span>
                        </button>
                      );
                    } else if (key.label === "Next &raquo;") {
                      return (
                        <button
                          onClick={(e) => onPaginate("next")}
                          className="relative inline-flex items-center px-2 py-2   text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Next</span>

                          <span className="text-light-slate">
                            <Icon path={mdiChevronRight} size={1} />
                          </span>
                        </button>
                      );
                    } else {
                      return (
                        <button
                          onClick={(e) => onPaginate(e)}
                          aria-current="page"
                          className={
                            key.active
                              ? " bg-purple-100 text-white rounded-md relative inline-flex items-center px-4 py-2 text-sm font-medium"
                              : "  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-medium"
                          }
                          value={key.label}
                        >
                          {key.label}
                        </button>
                      );
                    }
                  })}
                </nav>
              </div>
            </div>
          </div>
          {detailItem ? (
            <div className="flex self-center justify-end col-span-2">
              <button onClick={onBack}>
                <Button label="close" tipe="tertiary" size="small" />
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Pagination;
