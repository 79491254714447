import {
  mdiUnfoldMoreHorizontal,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import Pagination from "../layout/Pagination";
function BatchTable(props) {
  let bahasa = localStorage.getItem("bahasa");
  const { onClickDetail, data,filterPaginate,detailItem,onFilter } = props;
  const onDetail = (detail) => {
    onClickDetail(detail);
  };
  const [sort, setSort] = useState({
    title: "asc",
    started_at: "asc",
    campaigns_sum_collected_fund: "asc",
    campaigns_count: "asc"
  });
  const Filter = (name) => {
    console.log(name)
    if (sort[name] === "asc") {
      onFilter("-" + name,"batch");
      sort[name] = "dsc";
      setSort(sort);
    } else {
      onFilter(name,"batch");
      sort[name] = "asc";
      setSort(sort);
    }
  };
  let index = 1;
  return (
    <div>
      {data ? (
        <div>
          <div>
            <div className="mt-[30px] flex flex-col">
              <table className="min-w-full  border-separate table">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 text-left font-semibold "
                    >
                      <span className="text-light-slate flex ">
                        <span className="text-[14px] text-slate leading-[140%]">
                          No
                        </span>
                        <Icon path={mdiUnfoldMoreHorizontal} size={0.8} />
                      </span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="title" onClick={(e) => Filter("title")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? "Batch Name" : "Nama Kelompok"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                    <button name="campaigns_sum_collected_fund" onClick={(e) => Filter("campaigns_sum_collected_fund")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? "Total Fund" : "Total Pendapatan"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left ">
                    <button name="started_at" onClick={(e) => Filter("started_at")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en" ? "Periode" : "Periode"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                    <button name="campaigns_count" onClick={(e) => Filter("campaigns_count")}>
                      <div className="flex">
                        <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                          {bahasa === "en"
                            ? "Total campaign"
                            : "Total Kampanye"}
                        </span>
                        <span className="text-light-slate flex">
                          <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                        </span>
                      </div>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data["data"]
                    ? data["data"]["result"].map((key) => (
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {index++}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() =>
                                onDetail({
                                  status: true,
                                  id: key.id,
                                  title: key.title,
                                  periode:
                                    key.started_at + " - " + key.ended_at,
                                  fund: key.campaigns_sum_collected_fund,
                                  campaign: key.campaigns_count,
                                })
                              }
                            >
                              <span className="text-[14px] text-slate leading-[140%]">
                                {key.title}
                              </span>
                            </button>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="small text-purple-100 !font-bold leading-[140%]">
                              <CurrencyFormat
                                value={key.campaigns_sum_collected_fund}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                              />
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.started_at} - {key.ended_at}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="text-[14px] text-slate leading-[140%]">
                              {key.campaigns_count}
                            </span>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination data={data} filterPaginate={filterPaginate} detailItem={detailItem}/>
        </div>
      ) : null}
    </div>
  );
}

export default BatchTable;
