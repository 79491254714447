import { mdiFileDocumentMultiple, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import Buttons from "../form/Buttons";
import Stats from "../card/Stats";
import Blank from "../card/Blank";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Line from "../chart/line";
import Doughnut from "../chart/doughnut";
import api from "../../api";
import List from "../card/List";
import CurrencyFormat from "react-currency-format";

function Dashboard() {
  let bahasa = localStorage.getItem("bahasa");
  const [dataCompare, setDataCompare] = useState({
    ongoing: {
      condition: "down",
      percent: null,
      lastyear: 0,
      currentyear: 0,
    },
    finish: {
      condition: "down",
      percent: null,
      lastyear: 0,
      currentyear: 0,
    },
    fund: {
      condition: "down",
      percent: null,
      lastyear: 0,
      currentyear: 0,
    },
  });
  const [dataDuration, setDataDuration] = useState("year");
  const [roles, setRoles] = useState(null);
  var arr=[];
  const [topuser, settopuser] = useState(arr);
  const [dataline, setDataLine] = useState(null);
  const [filter, setFilter] = useState(true);
  const [dataYear, setLineYear] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(100, 212, 138, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(0, 183, 255, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(52, 76, 183, 1)",
      },
    ],
  });
  const [dataMonth, setLineMonth] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(100, 212, 138, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(0, 183, 255, 1)",
      },
      {
        data: [],
        tension: 0.3,
        fill: false,
        borderColor: "rgba(52, 76, 183, 1)",
      },
    ],
  });
  const [dataDoughnut, setDataDoughnut] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [dataYearDoughnut, setYearDataDoughnut] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [dataMonthDoughnut, setMonthDataDoughnut] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
 
  const GetAll = () => {
    let oldDate;
    let newDate;
    let oldDateMonth;
    let newDateMonth;
      oldDate =
        new Date().getFullYear() -
        1 +
        "-01-01," +
        (new Date().getFullYear() - 1) +
        "-12-31";
      newDate =
        new Date().getFullYear() +
        "-01-01," +
        new Date().getFullYear() +
        "-12-31";
        oldDateMonth =
        new Date().getFullYear() -
        1 +
        "-01-01," +
        (new Date().getFullYear() - 1) +
        "-12-31";
      newDateMonth =
        new Date().getFullYear() +
        "-01-01," +
        new Date().getFullYear() +
        "-12-31";
    
    let endpoints = [
      "/api/campaigns/count" + "?filter[in_period]=" + oldDate,
      "/api/campaigns/count" + "?filter[in_period]=" + newDate,
      "/api/campaigns/sum-collected-fund" + "?filter[in_period]=" + oldDate,
      "/api/campaigns/sum-collected-fund" + "?filter[in_period]=" + newDate,
      "/api/fundings/sum-amount" + "?filter[in_period]=" + oldDate,
      "/api/fundings/sum-amount" + "?filter[in_period]=" + newDate,
      "/api/ethis-roles?include=users_count",
      "/api/campaigns/statistics?period=yearly",
      "/api/campaigns/statistics?period=monthly" ,
    ];
    Promise.allSettled(
      endpoints.map((endpoint) =>
        api.get(endpoint, {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
      )
    ).then(function (response) {
      
      getDataCard("ongoing", response[0].value.data);
      getDataCard("ongoing", response[1].value.data);
      getDataCard("finish", response[2].value.data);
      getDataCard("finish", response[3].value.data);
      getDataCard("fund", response[4].value.data);
      getDataCard("fund", response[5].value.data);
      let role = response[6].value.data;
      let endp = [];
      console.log("Roles",role)
      role.data.result.
        map((key, i) => {
          endp[i] =
            "/api/ethis-users/tops?filter[role_id]=" +
            key.id +
            "&sort=-total_funding&limit=5";
          dataDoughnut.labels[i] = key.name;
          dataDoughnut.datasets[0].data[i] = key.users_count;
          dataDoughnut.datasets[0].backgroundColor[i] = random_bg_color();
        });
        console.log(endp)
      Promise.allSettled(
        endp.map((endpoint) =>
          api.get(endpoint, {
            withCredentials: true,
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
        )
      ).then(function (response) {
       
        response.map((key, i) => {
          topuser["top"+i]=key.value.data.data.result;
        });
      }).catch(function (error) {
        console.log("Axios Error :: ", error);
      });;
      setDataCompare(dataCompare);
      setRoles(role);
      setDataDoughnut(dataDoughnut);
      settopuser(topuser);
      response[7].value.data.data.map((key, i) => {
          dataYear.labels[i] = key.label;
          dataYear.datasets[0].data[i] = key.funding;
          dataYear.datasets[1].data[i] = key.ongoing_campaigns;
          dataYear.datasets[2].data[i] = key.finished_campaigns;
        });
        response[8].value.data.data.map((key, i) => {
          dataMonth.labels[i] = key.label;
          dataMonth.datasets[0].data[i] = key.funding;
          dataMonth.datasets[1].data[i] = key.ongoing_campaigns;
          dataMonth.datasets[2].data[i] = key.finished_campaigns;
        });
        setLineMonth(dataMonth);
        setLineYear(dataYear);
        setDataLine(dataYear)
    }).catch(function (error) {
      console.log("Axios Error :: ", error);
    });;
  };
  const random_bg_color = () => {
    var x = Math.floor(Math.random() * 256);
    var y = 100 + Math.floor(Math.random() * 256);
    var z = 50 + Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";
    return bgColor;
  };
  const getDataCard=(tipe,data)=>{
    dataCompare[tipe]["lastyear"] = data["data"];
    dataCompare[tipe]["currentyear"] = data["data"];
    dataCompare[tipe]["percent"] =
      dataCompare[tipe]["currentyear"] != null &&
      dataCompare[tipe]["currentyear"] > 0
        ? Math.round(
            (dataCompare[tipe]["currentyear"] /
              dataCompare[tipe]["lastyear"]) *
              100
          )
        : dataCompare[tipe]["lastyear"] != null &&
          dataCompare[tipe]["lastyear"] > 0
        ? -100
        : 0;
    dataCompare[tipe]["percent"] < 0
      ? (dataCompare[tipe]["condition"] = "down")
      : (dataCompare[tipe]["condition"] = "up");
  }
  const changeStatistic=(filters)=>{
    setDataLine(filters?dataYear:dataMonth);
    setFilter(filters)
    
  }
  useEffect(() => {
 
    GetAll();
  }, []);
  return (
    <div>
      <div className="grid grid-cols-8">
        <div className="flex col-span-6 ">
          <div className="self-center leading-7 text-purple-100  h3">
            {bahasa === "en" ? "Overview" : "Gambaran"}
          </div>
          <span className="self-center ml-6 mr-2 font-medium text-light-slate">
            {bahasa === "en" ? "Show" : "Tampilkan"}
          </span>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="flex max-w-xs mt-2">
                <span className="self-center px-0 py-0 mx-0 my-0 text-sm font-semibold tracking-wider text-purple-100 border-0 link-16">
                  {filter?bahasa === "en" ? "This Year" : "Tahun Ini":bahasa === "en" ? "Month" : "Bulan"}
                </span>
                <span className="text-purple-100 link-16 ">
                  <Icon path={mdiChevronDown} size={1} />
                </span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="z-50 absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
              >
                <button
                  className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                  role="menuitem"
                  id="user-menu-item-0"
                  onClick={()=>changeStatistic(false)}
                >
                  {bahasa === "en" ? "Month" : "Bulan"}
                </button>
                <button
                  className="block px-4 py-2 text-sm tracking-wider text-light-slate link-16"
                  role="menuitem"
                  id="user-menu-item-1"
                  onClick={()=>changeStatistic(true)}
                >
                  {bahasa === "en" ? "Year" : "Tahun"}
                </button>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="flex justify-end col-span-2">
          <Buttons
            icon="true"
            size="small"
            tipe="primary"
            label="Download Report"
          >
            <Icon path={mdiFileDocumentMultiple} size={1} className="mr-2" />
          </Buttons>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 pt-6">
        <Stats
          heading={
            bahasa === "en" ? "Ongoing Campaign" : "kampanye Berlangsung"
          }
          number={<CurrencyFormat value={dataCompare["ongoing"]["currentyear"]} displayType={'text'} thousandSeparator={true} prefix={' '} />}
          percent={dataCompare["ongoing"]["percent"] + "%"}
          condition={dataCompare["ongoing"]["condition"]}
          compare={dataCompare["ongoing"]["lastyear"]}
        />
        <Stats
          heading={bahasa === "en" ? "Finished Campaign" : "Kampanye Selesai"}
          number={<CurrencyFormat value={dataCompare["finish"]["currentyear"]} displayType={'text'} thousandSeparator={true} prefix={' '} />}
          percent={dataCompare["finish"]["percent"] + "%"}
          condition={dataCompare["finish"]["condition"]}
          compare={dataCompare["finish"]["lastyear"]}
        />
        <Stats
          heading={bahasa === "en" ? "Total Funding" : "Total Pendanaan"}
          number={<CurrencyFormat value={dataCompare["fund"]["currentyear"]} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />}
          condition={dataCompare["fund"]["condition"]}
          percent={dataCompare["fund"]["percent"] + "%"}
          compare={dataCompare["fund"]["lastyear"]}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 pt-4 ">
        <div className="col-span-2 p-6 bg-white rounded-lg transform transition duration-500 hover:scale-105 z-20 hover:border-grey-50 hover:shadow-md">
          <div className="grid grid-cols-11 pb-6 ">
            <div className="col-span-5 body font-semibold text-base leading-[140%] tracking-wider ">
              {bahasa === "en" ? "Statistic" : "Statistik"}
            </div>
            <div className="grid content-end grid-cols-3 col-span-6">
              <div className="flex ">
                <div className="self-center w-2 h-2 rounded-full bg-green-75"></div>
                <div className="self-center text-light-slate font-medium text-[10px] leading-[140%] ml-2">
                  {bahasa === "en"
                    ? "Ongoing Campaign"
                    : "Kampanye Berlangsung"}
                </div>
              </div>
              <div className="flex">
                <div className="self-center w-2 h-2 bg-blue-100 rounded-full "></div>
                <div className="self-center text-light-slate font-medium text-[10px] leading-[140%] ml-2">
                  {bahasa === "en" ? "Finished Campaign" : "Kampanye Selesai"}
                </div>
              </div>
              <div className="flex">
                <div className="self-center w-2 h-2 rounded-full bg-dark-blue-100 "></div>
                <div className="self-center text-light-slate font-medium text-[10px] leading-[140%] ml-2">
                  {bahasa === "en" ? "Funding" : "Pendapatan"}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Line dataLine={dataline} />
          </div>
        </div>
        <div className="col-span-1 p-6 bg-white rounded-lg transform transition duration-500 hover:scale-105  hover:border-grey-50 hover:shadow-md">
          <div className="body font-semibold text-base leading-[140%] tracking-wider pb-4">
            {bahasa === "en" ? "User" : "Pengguna"}
          </div>
          <div className="px-8">
            <Doughnut dataDoughnut={dataDoughnut} />
          </div>
          {roles
            ? roles.data.result
              ? roles.data.result.map((key, i) => (
                  <div className="grid grid-cols-5 px-3 py-[9px] gap-y-3">
                    <div className="flex col-span-3">
                      <div
                        className="flex self-center w-2 h-2 align-middle rounded-2xl "
                        style={{
                          backgroundColor:
                            dataDoughnut.datasets[0].backgroundColor[i],
                        }}
                      ></div>
                      <div className="flex align-middle self-center ml-2 small font-medium leading-[140%]">
                        {key.name}
                      </div>
                    </div>
                    <div className="flex justify-end col-span-2 body font-bold text-base leading-[160%]">
                      {key.users_count}
                    </div>
                  </div>
                ))
              : null
            : null}
        </div>
      </div>
      {/* Belum DI TEST */}
      
      {/* <div className="grid grid-cols-3 gap-4 py-4 ">
        {console.log("User",topuser)}
        {console.log("User",topuser.length)}
        {topuser
          ? Object.keys(topuser).map((key, i) => (
              <Blank label="Top Individual Funder">
                {Object.keys(key.top+i).map((list,i)=>{
                  {console.log("List")}
                  <List nama={list.nama?list.nama:""} nominal={<CurrencyFormat value={parseInt(list.total_funded?list.total_funded:0, 10)} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />} tipe="Funding">
                  <img src={list.avatar?list.avatar:""} alt="" />
                </List>
                })}
                <div className="pt-6">
                  <Buttons
                    tipe="tertiary"
                    size="small"
                    label="Show more"
                    full="true"
                  />
                </div>
              </Blank>
            ))
          : null}
      </div> */}
    </div>
  );
}

export default Dashboard;
