import {
  mdiUnfoldMoreHorizontal,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import Pagination from "../layout/Pagination";
function DireTable(props) {
  const { data, onFilter,filterPaginate } = props;
  const [sort, setSort] = useState({
    no: "asc",
    date: "asc",
    dire: "asc",
    debit: "asc",
    balance: "asc",
    note: "asc",
  });
  const Filter = (name) => {
    console.log(name)
    if (sort[name] === "asc") {
      onFilter("-" + name);
      sort[name] = "dsc";
      setSort(sort);
    } else {
      onFilter(name);
      sort[name] = "asc";
      setSort(sort);
    }
  };
  let bahasa = localStorage.getItem("bahasa");
  return (
    <div>
      <div>
        <div className="mt-[30px] flex flex-col">
          <table className="min-w-full  border-separate table ">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="px-3 py-3.5 text-left ">
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4">
                        No
                      </span>
                    </div>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <button name="date" onClick={(e) => Filter("date")}>
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                        Date
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </button>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <button name="dire" onClick={(e) => Filter("number")} >
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                        Dire ID
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </button>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <button name="debit" onClick={(e) => Filter("debit")}>
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                        Debit
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </button>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <button name="credit" onClick={(e) => Filter("credit")}>
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                        Credit
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </button>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 text-left font-semibold "
                >
                  <button
                    name="balance"
                    onClick={(e) => Filter("balance")}
                  >
                  <div className="flex">
                    <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                      Balance
                    </span>
                    <span className="text-light-slate flex">
                      <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                  </div>
                  </button>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left ">
                  <button name="note" onClick={(e) => Filter("note")}>
                    <div className="flex">
                      <span className="flex self-center text-[12px] text-light-slate leading-4 font-bold">
                        Note
                      </span>
                      <span className="text-light-slate flex">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                      </span>
                    </div>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data
                ? data.data.result
                  ? data.data.result.map((key, i) => (
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                          {i + 1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                         
                            <div className="flex">
                              <span className="text-[14px] text-slate leading-[140%] self-center">
                                {key.date}
                              </span>
                            </div>
                         
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="small text-slate leading-[140%]">
                            {key.number}
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="text-[14px] text-slate leading-[140%]">
                            <CurrencyFormat
                              value={key.debit ? key.debit : 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                            />
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="text-[14px] text-slate leading-[140%]">
                            <CurrencyFormat
                              value={key.credit ? key.credit : 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                            />
                          </span>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 text-sm ">
                          <span className="small !font-semibold text-purple-100 leading-[140%]">
                            <CurrencyFormat
                              value={key.balance ? key.balance : 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                            />
                          </span>
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500 ">
                          <span className="text-[14px] text-slate leading-[140%] break-words">
                            {key.note}
                          </span>
                        </td>
                      </tr>
                    ))
                  : null
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination data={data} filterPaginate={filterPaginate}  />
    </div>
  );
}

export default DireTable;
