import "../../index.css";
import Button from "../form/Buttons";
import reset from "../../assets/Reset.png"
import {
    mdiEmailOutline
  } from "@mdi/js";
import Icon from "@mdi/react";
import AuthImage from "../layout/AuthImage";


function CheckEmail() {
    return (
        <div className="flex">
           <AuthImage image={reset}/>

            <div className="flex w-full sm:w-1/2 p-4 bg-white min-h-screen items-center justify-center">
                <div className="flex flex-col w-[349px] gap-8">
                    <div className="w-[56px] justify-center  items-center p-4 rounded-full bg-side text-purple-100">
                        <Icon path={mdiEmailOutline} size={1} />
                    </div>
                    <div>
                        <h2 className="text-[40px] font-bold text-slate">Check your email</h2>
                        <p className="text-light-slate">We sent a password reset link to iniemailku@gmail.com</p>
                    </div>
                    <p className="text-light-slate"><span className="body font-medium text-slate">Not receiving email?</span> Please check the spam folder before clicking the resend email button</p>
                    <Button tipe="tertiary" size="large" label="Resend Email" full="true"></Button>
                </div>
            </div>
        </div>
    );
}

export default CheckEmail;